import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import PackageLogoGrid from './PackageLogoGrid';

const PackageFooter = ({ showHelpSection }) => {

    const option = {
        loop: true,
        margin: 10,
        nav: true,
        navText: ['<i class="fa-solid fa-angle-left"></i>', '<i class="fa-solid fa-angle-right"></i>'],
        dots: false,
        autoplay: true,
        autoplayTimeout: 3000,   
        smartSpeed: 600,     
        items: 1,
    }

    return (
        <div className='package_footer bg-white'>
            {
                showHelpSection && (
                    <>
                        <div className='container mb-4'>
                            <div className='back_img text-center'>
                                <h1 className='lg:text-[50px] md:text-[40px] text-[30px] mb-[30px] text-white'>Achieve your results <br /> <span className='text-[#413ada] italic'>with Koinpr EOD</span></h1>
                                <p className='text-[#b3b3b3] md:text-lg'>Expertise on demand to execute, monitor, and strategize.</p>
                                <a href='https://koinpr.com/call' className='py-[12px] px-[38px] inline-block !no-underline text-sm rounded-lg mt-[32px] btn_hover duration-200 hover:!border-[#413ada] border-[1px] border-white text-white'>Book a Call</a>
                            </div>

                            <PackageLogoGrid />

                            <div className='mt-5'>
                                <OwlCarousel className="owl-theme w-full package_slider" {...option}>
                                    <div className='item col-md-8 mx-auto'>
                                        <div className="lg:px-[80px] px-[30px] py-[40px] bg-[#f8f8f8] rounded-2xl text-center">
                                            <img src="/assets/images/carousel/logo4.png" alt="logo" className='max-w-[160px] mx-auto mb-[32px]' />
                                            <p className='mb-[25px]'>Thanks to Koinpr’s tailored media strategy, we achieved over 800K impressions and grew our community by 12K in just seven days. It was a game-changer for our brand!</p>
                                            <h1 className='text-2xl'>Lim</h1>
                                            <p className='text-[#413ada]'>Marketing Manager, Ponder</p>
                                        </div>
                                    </div>
                                    <div className='item col-md-8 mx-auto'>
                                        <div className="lg:px-[80px] px-[30px] py-[40px] bg-[#f8f8f8] rounded-2xl text-center">
                                            <img src="/assets/images/carousel/logo7.png" alt="logo" className='max-w-[160px] mx-auto mb-[32px]' />
                                            <p className='mb-[25px]'>The Koinpr Marketplace saved us weeks of work by simplifying publisher selection and execution. We saw tangible results without the usual hassle of manual outreach.</p>
                                            <h1 className='text-2xl'>Jester</h1>
                                            <p className='text-[#413ada]'>Partner, MeTrade</p>
                                        </div>
                                    </div>
                                    <div className='item col-md-8 mx-auto'>
                                        <div className="lg:px-[80px] px-[30px] py-[40px] bg-[#f8f8f8] rounded-2xl text-center">
                                            <img src="/assets/images/carousel/logo3.png" alt="logo" className='max-w-[160px] mx-auto mb-[32px]' />
                                            <p className='mb-[25px]'>Launching an ICO is no small feat, but Koinpr made it look effortless. Their strategies helped us raise $5 million in just three months—way beyond what we thought was possible. They were with us every step of the way, and their expertise was invaluable.</p>
                                            <h1 className='text-2xl'>Alex</h1>
                                            <p className='text-[#413ada]'>CMO, DTXExchange</p>
                                        </div>
                                    </div>
                                    <div className='item col-md-8 mx-auto'>
                                        <div className="lg:px-[80px] px-[30px] py-[40px] bg-[#f8f8f8] rounded-2xl text-center">
                                            <img src="/assets/images/carousel/logo5.png" alt="logo" className='max-w-[160px] mx-auto mb-[32px]' />
                                            <p className='mb-[25px]'>Koinpr’s Marketplace simplified everything for us. From selecting publishers to executing campaigns, the process was smooth and incredibly fast.</p>
                                            <h1 className='text-2xl'>Golda</h1>
                                            <p className='text-[#413ada]'>Marketing Team, Fairspin</p>
                                        </div>
                                    </div>
                                    <div className='item col-md-8 mx-auto'>
                                        <div className="lg:px-[98px] px-[30px] py-[40px] bg-[#f8f8f8] rounded-2xl text-center">
                                            <img src="/assets/images/carousel/logo6.png" alt="logo" className='max-w-[160px] mx-auto mb-[32px]' />
                                            <p className='mb-[25px]'>We were struggling to get traction for our product launch until we partnered with Koinpr. Their team understood our needs and helped us attract over 1,500+ sign-ups within 3 weeks. The process was seamless, and the results were better than we imagined.</p>
                                            <h1 className='text-2xl'>Saurabh Pandey</h1>
                                            <p className='text-[#413ada]'>CEO, Mavex</p>
                                        </div>
                                    </div>
                                </OwlCarousel>
                            </div>
                        </div>
                    </>
                )
            }
            <div className='md:px-[48px] px-[12px] pt-[50px] pb-[20px] bg-white'>
                <div className="d-lg-flex justify-between md:pb-[80px] pb-[50px] border-b border-black">
                    <div className="logo"><a href="/Dashboard"><img src="/assets/images/logo.png" alt="" /></a></div>
                    <ul className='d-flex flex-wrap justify-content-md-start justify-content-center items-center lg:my-auto !my-[20px]'>
                        <li><a href="/Marketplace" className='no-underline font-[500] md:text-sm text-[13px] text-black pe-md-4 me-md-2 pe-3'>Marketplace</a></li>
                        <li><a href="https://koinpr.com/koinpr-one" target="_blank" className='no-underline font-[500] md:text-sm text-[13px] text-black pe-md-4 me-md-2 pe-3'>Koinpr One</a></li>
                        <li><a href="https://koinpr.com/koinpr-eod" target="_blank" className='no-underline font-[500] md:text-sm text-[13px] text-black pe-md-4 me-md-2 pe-3'>Koinpr EOD</a></li>
                        <li><a href="/Package" className='no-underline font-[500] md:text-sm text-[13px] text-black pe-md-4 me-md-2 pe-3'>Packages</a></li>
                        <li><a href="https://koinpr.com/about" target="_blank" className='no-underline font-[500] md:text-sm text-[13px] text-black pe-md-4 me-md-2 pe-3'>About Us</a></li>
                        <li><a href="https://koinpr.com/contact" target="_blank" className='no-underline font-[500] md:text-sm text-[13px] text-black'>Contact Us</a></li>
                    </ul>
                    <div className='d-flex items-center gap-3'>
                        <a href="https://www.linkedin.com/company/koinpr" target="_blank"><img src="/assets/images/Package/linkedin.svg" alt="social" /></a>
                        <a href="https://x.com/koinpr" target="_blank"><img src="/assets/images/Package/twitter.svg" alt="social" /></a>
                        <a href="#youtube"><img src="/assets/images/Package/youtube.png" alt="social" /></a>
                    </div>
                </div>
                <div className='d-flex md:flex-row flex-col md:gap-3 gap-2 justify-center mt-4 items-center'>
                    <span className='md:text-base text-[14px]'>© Koinpr Marketing Private Limited. All rights reserved.</span>
                    <div className='flex justify-between items-center md:gap-4 gap-2'>
                        <a href="https://koinpr.com/privacy-policy" target="_blank" className='text-black md:text-base text-[13px] underline-offset-4'>Privacy Policy</a>
                        <a href="https://koinpr.com/terms-and-conditions" target="_blank" className='text-black md:text-base text-[13px] underline-offset-4'>Terms and Conditions</a>
                        {/* <a href="#" className='text-black md:text-base text-[13px] underline-offset-4'>Cookie Policy</a> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PackageFooter;