// redux/reducers/filter.reducer.js

import {
  SET_FILTER,
  RESET_FILTER,
  TOGGLE_ARRAY_FILTER,
  SET_SELECT_FILTER,
  RESET_ALL_FILTERS,
} from "../actions/filter.action";

const initialState = {
  filters: {
    categories: [],
    region: [],
    budget: null,
    allowedContent: [], 
  },
  selectFilter: {},
};

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.filterType]: action.payload.value,
        },
      };

    case RESET_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload]: Array.isArray(state.filters[action.payload]) ? [] : "",
        },
      };

    case TOGGLE_ARRAY_FILTER:
      const currentArray = state.filters[action.payload.filterType];
      const updatedArray = currentArray.includes(action.payload.value)
        ? currentArray.filter((item) => item !== action.payload.value) // Remove value
        : [...currentArray, action.payload.value]; // Add value
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.filterType]: updatedArray,
        },
      };

    case SET_SELECT_FILTER:
      return {
        ...state,
        selectFilter: {
          ...state.selectFilter,
          [action.payload.key]: action.payload.value,
        },
      };

    case RESET_ALL_FILTERS:
      return initialState;

    default:
      return state;
  }
};

export default filterReducer;
