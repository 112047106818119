import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate,Link } from "react-router-dom";
import { forgetPassword } from "../redux/actions/auth.action";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(""); 
  const navigate = useNavigate();
  const dispatch = useDispatch();

 
  const { error: reduxError, loading, passwordResetEmailSent } = useSelector(
    (state) => state.authentication
  );

  useEffect(() => {
   
    if (reduxError) {
      // setError(reduxError);
    }
  }, [reduxError]);

  const handleSubmit = (e) => {
    e.preventDefault();

    
    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setError("Please enter a valid email address.");
      return; 
    }

    setError("");

    
    dispatch(forgetPassword(email));
  };

  useEffect(() => {
   
    if (passwordResetEmailSent) {
      navigate("/reset-pass", { state: { email } });
    }
  }, [passwordResetEmailSent, error, navigate, email]); 

  return (
    <div className="forget-password h-screen flex items-center justify-center">
      <div className="w-full max-w-md px-4">
        <h3 className="text-[24px] text-center mb-4">Forget Password</h3>
        <p className="text-center mb-5">
          Enter your email address and we’ll send you an OTP to reset your password.
        </p>
        <form onSubmit={handleSubmit}>
          <div className="mb-4 ">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email Address"
              className="sign-input w-full p-2"
            />
            {error && <p className="text-danger mt-2">{error}</p>}
          </div>
          <button
            type="submit"
            className="bg-black-color block text-white rounded-lg w-full h-[46px] mt-4"
            disabled={loading} // Disable button while loading
          >
            {loading ? "Sending OTP..." : "Send OTP"}
          </button>
        </form>
        <p className="text-center mt-4">
          Already have an account?{" "}
          <Link to="/sign-in" className="text-primary underline">
            Log In
          </Link>
        </p>
      </div>
    </div>
  );
};

export default ForgetPassword;
