import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { IoCallOutline } from "react-icons/io5";
import { FaTelegram } from "react-icons/fa6";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import api, { authApi } from "../../api";
import { toast } from "react-hot-toast";

export default function MarketplaceBrand({ selectedCategory = "" }) {
  const navigate = useNavigate();
  const [trendingPackages, setTrendingPackages] = useState([]);
  
  const verticalSettings = {
    dots: false,
    arrows: false,
    // infinite: true,
    vertical: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    // autoplay: true,
    autoplaySpeed: 3000,
    swipeToSlide: true,
    draggable: false,
    verticalSwiping: true,
  };
  useEffect(() => {
    const fetchTrending = async () => {
      try {
        const response = await api.get("/trending/fetch?status=active");
        console.log({ data: response?.data?.data });

        const data = response?.data?.data?.filter((item) => {
          if (!selectedCategory) {
            return item?.showOnGeneral;
          } else {
            return item?.showOnCategory?.some(
              (category) => category?.categoryId === selectedCategory
            );
          }
        });

        setTrendingPackages(data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchTrending();
  }, []);

  const handleFetch = async (id) => {
    try {
      const {
        data: { offeringList },
      } = await authApi.get(`/offerings/getTrending/${id}`);

      navigate(
        `/partner/${offeringList?.userId?._id || offeringList?.userId}/${
          offeringList?.offeringCategory?.name
        }`,
        {
          state: { ...offeringList, targetId: id },
        }
      );
    } catch (err) {
      toast.error("Something Went Wrong");
    }
  };

  return (
    <>
      <div className="sticky top-[90px] ps-xl-3">
        {Array.isArray(trendingPackages) && trendingPackages?.length > 0 ? (
          <div className="sliders">
            <OwlCarousel
              className="owl-theme market_slide"
              margin={10}
              items={1}
              autoplay={true}
              dots={true}
            >
              {trendingPackages?.map((group, groupIndex) => {
                return (
                  <div className="item" key={groupIndex}>
                    <h4 className="font-[590] text-[16px] lg:text-[14px] xl:text-[16px] leading-[19.2px] lg:leading-none xl:leading-[19.2px] text-[#474747]">
                      {group?.packageName}
                    </h4>
                    {Array.isArray(group?.offerings) &&
                      group?.offerings?.length > 0 &&
                      group?.offerings?.map((item) => (
                        <div
                          className="flex items-center justify-between pt-[20px] cursor-pointer"
                          key={item?._id}
                          onClick={() => {
                            handleFetch(item?.offeringTypeId);
                          }}
                        >
                          <div className="flex items-center gap-3 ">
                            <div>
                              <img
                                src="/assets/images/Home-Page/marketPlace1.png"
                                alt="market"
                              />
                            </div>
                            <div>
                              <h4 className="font-[510] mt-1 capitalize text-[16px] lg:text-[14px] xl:text-[16px] mb-0 leading-[19.2px] lg:leading-none xl:leading-[19.2px]">
                                {item?.offeringTypeIds?.typeId?.name}
                              </h4>
                            </div>
                          </div>
                          <div>
                            <h4 className="font-[510] text-[14px] leading-[16.71px] mb-0 text-[#0E0E0E]">
                              $
                              
                      {item?.livePrice > 0
                                    ? item?.livePrice
                                    : item?.mediaDiscountedKitPrice > 0
                                    ? item?.mediaDiscountedKitPrice 
                                    : item?.mediaKitprice || 0}
                              {/* {item.offeringTypeIds?.livePrice > 0
                                ? item.offeringTypeIds?.livePrice
                                : item.offeringTypeIds?.mediaKitprice} */}
                            </h4>
                          </div>
                        </div>
                      ))}
                  </div>
                );
              })}
            </OwlCarousel>
          </div>
        ) : (
          <h4 className="font-[590] text-[16px] lg:text-[14px] xl:text-[16px] leading-[19.2px] lg:leading-none xl:leading-[19.2px] text-[#474747]">
            No Trending Package
          </h4>
        )}

        <div className="include mt-[20px] py-[16px] px-[20px]">
          <div className="mb-3">
            <h1 className="font-[590] text-[20px] leading-[24px] text-[#FFFFFF] flex gap-3">
              <img
                src="/assets/images/MarketPlace/list.png"
                className="bg-[#6E6E6E] self-start rounded-lg w-[26px]"
                alt="list"
              />
              Unlock Growth Packages
            </h1>
          </div>
          <div className="listing">
            <Slider {...verticalSettings} className="text-sm">
              <div>
                <li>TikTok Influencer Packages</li>
              </div>
              <div>
                <li>Organic Article Packages</li>
              </div>
              <div>
                <li>Press Release Packages</li>
              </div>
              <div>
                <li>ICO Conversion Packages</li>
              </div>
              <div>
                <li>Twitter Influencer Packages</li>
              </div>
              <div>
                <li>Instagram Influencer Packages</li>
              </div>
              <div>
                <li>Youtube Influencer Packages</li>
              </div>
            </Slider>
          </div>
          <div>
            <button className="font-[510] hover:!bg-gray-100 duration-200 text-[13px] leading-[15.51px] text-[#1D1D1D] bg-[#FFFFFF] px-[8px] py-[6px] rounded-[4px] w-[100%] mt-[12px]">
              Open Packages
            </button>
          </div>
        </div>

        {/* <div className="roadmap mt-[20px] py-[16px] px-[20px] d-lg-block d-none">
          <div>
            <h1 className="font-[590] text-[20px] leading-[24px] text-[#FFFFFF]">
              Marketing Roadmap? <br />
              It’s on us.
            </h1>
          </div>
          <div className="d-xl-flex justify-between items-center mt-[20px]">
            <button className="flex items-center font-[510] py-[5px] px-[15px] bg-[#FFFFFF] text-[13px] rounded-[4px] leading-[15.51px] gap-[4px] text-[#1D1D1D] ">
              <IoCallOutline className="text-[#000000] text-[24px]" /> Schedule
              call
            </button>
            <button className="font-[510] flex items-center gap-[4px] text-[13px] py-[10px] px-[8px] leading-[15.51px] text-[#FFFFFF]">
              {" "}
              <FaTelegram className="text-[#fff] text-[24px]" />
              Text on Telegram
            </button>
          </div>
        </div> */}
      </div>
    </>
  );
}
