import { combineReducers } from "redux";
import authReducer from "./auth.reducer";
import marketplaceReducer from "./marketplace.reducer";
import offeringReducer from "./offering.reducer";
import cartReducer from "./cart.reducer";
import filterReducer from "./filter.reducer";

const rootReducer = combineReducers({
  authentication: authReducer,
  marketplace: marketplaceReducer,
  offerings: offeringReducer,
  cart: cartReducer,
  filters: filterReducer,
});

export default rootReducer;
