import React, { useCallback, useEffect, useState } from "react";
import { IoIosCheckmark } from "react-icons/io";
import { FaSquarePlus } from "react-icons/fa6";
import BannerExample1 from "./BannerExample1";
// import { BASEURL } from "../../baseUrl";
// import axios from "axios";
import useAddCart from "../../hooks/useAddCart";
import { authApi } from "../../api";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FaCheckCircle } from "react-icons/fa";
import { fetchCartData } from "../../redux/actions/cart.actions";
import { formateNumber } from "../common/formateNumber";
import PackageSkeletonLoader from "./packageSkeleton";

const PopluarPackage = ({ isAdvertiser, selectedPackage, userId }) => {
  const { addToCart, loading } = useAddCart();
  const cartItems = useSelector((state) => state.cart.cartItems);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [apiLoading, setApiLoading] = useState(false)
  const [packages, setPackages] = useState([]);

  const fetchPackages = async () => {
    try {

      setApiLoading(true);

      const response = await authApi(`packages/one`, {
        params: {
          status: "approved",
          userId: userId,
        },
      });

      setPackages(response?.data?.packageList);
    } catch (error) {
      console.log(error);
    }finally{
      setApiLoading(false)
    }
  };

  useEffect(() => {
    fetchPackages();
  }, []);

  const isAuthenticated = useSelector(
    (state) => state.authentication.isAuthenticated
  );

  const handleAddToCart = async (itemId) => {
    try {
      await addToCart("packages", itemId);
      dispatch(fetchCartData());
    } catch (error) {
      console.error("Error adding to cart:", error);
    }
  };

  const checkItemExistInCart = useCallback(
    (itemId) => {
      if (!cartItems) return false;

      const allItems = [
        ...(cartItems.adminPackages || []),
        ...(cartItems.offerings || []),
        ...(cartItems.addOn || []),
        ...(cartItems.packages || []),
      ];

      return allItems.some(
        (item) =>
          item._id === itemId || item.id === itemId || item.id?._id === itemId
      );
    },
    [cartItems]
  );

  return (
    <div className="bg-white shadows mb-4 p-md-4 p-3 rounded-lg border-1 border-[#DBDBDB]">
      <h3 className="md:text-[24px] text-[20px] font-[590] mb-4">
        Popular packages
      </h3>
      <div className="d-flex flex-nowrap whitespace-nowrap overflow-x-auto gap-3 package_scroll">
        { apiLoading ? [...Array(4)].map((_, idx) => <PackageSkeletonLoader key={idx} />):
        packages?.length > 0 ? (
          packages.map((plan) => {
            const isExist = checkItemExistInCart(plan._id);

            return (
              <div key={plan?._id} className="md:w-[300px] w-[230px] flex-shrink-0">
                <div className="card h-100 bg-white border-1 border-[#DBDBDB] shadow-[0px_4px_28px_#0000001C] rounded-lg overflow-hidden">
                  <div className="bg-[#F5F5F5] p-2 d-flex justify-between items-center">
                    <span className="font-[590] capitalize text-[13px]">
                      {plan?.name}
                    </span>
                    <span className="font-[590]">${formateNumber(plan?.totalAmount)}</span>
                  </div>
                  <div className="p-2 card-body h-[300px]">
                    <img
                      src="/assets/images/expanded/newslogo.png"
                      alt="newslogo"
                      className="w-[32px] mb-2"
                    />
                    {/* <p className="text-[13px]">{plan?.additionalDetails}</p> */}
                    <h3 className="text-base font-[590]">Includes</h3>
                    <ul>
                      {plan?.offerings?.map((item, idx) => {
                        const matchingCategory = plan?.contentOfferingsType?.[
                          idx
                        ]?.[0]?.contentOfferings?.find(
                          (category) => category?._id === item?.id
                        );

                        return matchingCategory ? (
                          <li key={item?._id} className="flex items-center">
                            <IoIosCheckmark />
                            <span className="text-[13px] font-[500]">
                             {item.qty} {matchingCategory?.typeId?.name}
                            </span>
                          </li>
                        ) : null;
                      })}
                    </ul>
                    <br></br>
                    <h3 className="text-base font-[590]">Additional Details</h3>
                    <div
                    className="contentWrapper list-inside"
                    dangerouslySetInnerHTML={{
                      __html: plan?.additionalDetails.replace(
                        /<([a-zA-Z]+)(.*?)>/g,
                        '<$1$2 style="all: revert; margin: 0; padding: 0;">'
                      ),
                    }}
                  />
                  </div>
                  <div className="bg-[#F5F5F5] card-footer border-0 p-2 d-flex justify-end items-center">
                    {isAdvertiser && (
                      <>
                        {!isExist ? (
                          <span className="font-[590] text-[13px] flex items-center gap-1">
                            Add to cart
                            <FaSquarePlus
                              className="text-2xl  cursor-pointer  "
                              onClick={() => {
                                if (!isAuthenticated) {
                                  navigate("/sign-in");
                                  return;
                                }
                                if (!loading) {
                                  handleAddToCart(plan?._id);
                                }
                              }}
                              disabled={loading}
                            />
                          </span>
                        ) : (
                          <span className="font-[590] text-[13px] flex items-center gap-1">
                            Added to cart
                            <FaCheckCircle className="text-lg cursor-pointer text-green-500" />
                          </span>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="flex ">
            <p className="text-center text-lg ">No package found</p>
          </div>
        )}        
      </div>

      {/* <BannerExample1 /> */}
    </div>
  );
};

export default PopluarPackage;
