import React, { useState, useEffect } from "react";
// import { BiEditAlt } from "react-icons/bi";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { authApi } from "../../api";
import { formateNumber } from "../common/formateNumber";

const CreateLive = ({
  isPackageCreated,
  packageType,
  setPackageType,
  offeringList,
  fetchPackagesData,
  data,
}) => {
  // const [offeringCategoryLs, setOfferingCategoryLs] = useState([]);
  // const [selectedCategory, setSelectedCategory] = useState([]);

  useEffect(() => {
    fetchPackagesData();
  }, []);

  // useEffect(() => {
  //   const fetchOfferingCategoryLs = async () => {
  //     try {
  //       const response = authApi.get(`/offering-categories`);
  //       setOfferingCategoryLs((await response).data.offeringCategoryList);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   fetchOfferingCategoryLs();
  // }, []);

  return (
    <>
      {data ? (
        <div className="bg-white border-1 border-[#DBDBDB] rounded-lg py-3 px-4 h-full package">
          <div className="flex items-center justify-between mt-[16px] pb-[16px]">
            <h3 className="md:text-[18px] text-base font-[590] mb-0">
              Your Live Packages
            </h3>
          </div>
          {/* <div className="mb-3">
            <div className="dropdown rounded-lg bg-white mb-3">
              <div className="w-[100%]">
                <label
                  htmlFor="offeringCategoryId"
                  className="font-[510] text-[14px] leading-[21px] text-[#474747]"
                >
                  Select Category
                </label>{" "}
                <br />
                <select
                  name="offeringCategoryId"
                  className="w-[100%] py-[12px] px-[13px] rounded-[8px] mt-2 border-1 border-[#DBDBDB] Outline"
                  id="offeringCategoryId"
                  onChange={(e) => {
                    setSelectedCategory(e.target.value);
                  }}
                  value={selectedCategory}
                >
                  <option
                    className="font-[400] text-[14px] leading-[21px] text-[#474747]"
                    disabled
                    value=""
                  >
                    Choose Category
                  </option>
                  {offeringCategoryLs?.map((item, index) => (
                    <option
                      key={item?._id || index}
                      value={item._id}
                      data-id={item.name}
                    >
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div> */}
          <div className="pt-">
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="Basic-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#Basic"
                  type="button"
                  role="tab"
                  onClick={() => setPackageType("basic")}
                  aria-controls="Basic"
                  aria-selected="true"
                >
                  Basic
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="Advance-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#silver"
                  type="button"
                  role="tab"
                  onClick={() => setPackageType("silver")}
                  aria-controls="silver"
                  aria-selected="false"
                >
                  Silver
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="advance-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#advance"
                  type="button"
                  role="tab"
                  onClick={() => setPackageType("advance")}
                  aria-controls="advance"
                  aria-selected="false"
                >
                  Advance
                </button>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              {data.map((pkg, index) => {
                if (
                  packageType === pkg.name
                  // &&
                  // (selectedCategory === pkg?.offeringCategoryId?._id ||
                  //   selectedCategory === pkg?.offeringCategoryId)
                )
                  return (
                    <div
                      key={pkg._id}
                      className={`tab-pane fade mb-2 ${
                        index.toString() ? "show active" : ""
                      }`}
                      id={pkg.name}
                      role="tabpanel"
                      aria-labelledby={`${pkg.name}-tab`}
                      tabIndex="0"
                    >
                      <div className="borders rounded-lg">
                        <h3 className="md:text-[20px] text-base font-[590] border-b border-[#DBDBDB] p-2 capitalize">
                          {pkg.name}
                        </h3>
                        <div className="p-2">
                          <p className="text-[#474747] md:text-base text-[13px] font-[590]">
                            Offerings
                          </p>
                          <ul>
                            {pkg.offerings.map((offering) => (
                              <li
                                key={offering.id._id}
                                className="bg-[#F9F9F9] borders md:text-base text-[13px] flex items-center p-2"
                              >
                                <IoIosCheckmarkCircle className="me-2" />
                                {offering.qty} {"  "}
                                {offering?.name ??
                                  offeringList?.find(
                                    (i) =>
                                      i?._id?.toString() ===
                                      offering?.id?.toString()
                                  )?.typeId?.name}
                              </li>
                            ))}
                          </ul>
                          <div>
                            <label className="text-sm font-[510] my-3">
                              Other offerings
                            </label>
                            <textarea
                              placeholder="Type description here...."
                              value={pkg.additionalDetails}
                              className="h-[113px] w-full border-1 bg-[#F5F5F5BA] border-[#DBDBDB] rounded-md p-[10px]"
                            ></textarea>
                          </div>
                          <div className="flex items-center gap-1 mb-3">
                            <div className="flex items-center bg-[#EDEDED] md:px-[20px] md:py-[10px] px-[16px] py-[8px] text-[12px] rounded-md">
                              <img
                                src="/assets/images/account/payments.png"
                                className="me-2"
                              />
                              Total: ${formateNumber(pkg.totalAmount)}
                            </div>
                            <div className="flex items-center bg-[#EDEDED] md:px-[20px] md:py-[10px] px-[16px] py-[8px] text-[12px] rounded-md">
                              <img
                                src="/assets/images/account/payments.png"
                                className="me-2"
                              />
                              Discounted: ${formateNumber(pkg.discountedAmount)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
              })}
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-white border-1 border-[#DBDBDB] rounded-lg py-3 px-4 h-full">
          <h3 className="text-[18px] font-[590] mb-5">Your Live Packages</h3>
          <div className="text-center pt-5">
            <img
              src="/assets/images/account/file.png"
              className="w-[100px] mx-auto"
              alt=""
            />
            <h3 className="md:text-[24px] text-lg font-[510] mt-3">
              You have no any live packages
            </h3>
            <p className="md:text-base text-[13px] text-[#737373]">
              Once you create packages, they will appear here.
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateLive;
