import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { RiCloseLine } from "react-icons/ri";
import { PiUserCircleFill } from "react-icons/pi";
import { IoCartOutline } from "react-icons/io5";
import { CiBookmark } from "react-icons/ci";
import { RxCounterClockwiseClock } from "react-icons/rx";
import { IoMdAlert } from "react-icons/io";
import { IoCallOutline } from "react-icons/io5";
import { FaArrowRight, FaTelegram } from "react-icons/fa6";
import { MdLogout } from "react-icons/md";
import { LuLogIn } from "react-icons/lu";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/actions/auth.action";
import { PiPackageFill } from "react-icons/pi";


const Sidebar = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));
  const handleLogout = async () => {
    await dispatch(logout());
    setTimeout(() => {
      navigate("/sign-in");
    }, 1000);
  };
  return (
    <>
      {isOpen && (
        <div
          className="fixed top-0 right-0 h-full w-full bg-[#93939399] z-[999]"
          onClick={onClose}
        ></div>
      )}
      <div
        className={`fixed top-0 right-0 h-full w-[20rem] overflow-y-auto bg-[#FFF] z-[1000] transition-transform transform ${isOpen ? "translate-x-0" : "translate-x-full"
          }`}
      >
        <div className="d-flex flex-column justify-content-between h-full">
          <div>
            <div className="flex justify-between items-center p-4 ">
              <a className="navbar-brand" href="#">
                <img
                  src="/assets/images/logo/koinpr-Logo-Dark.png"
                  alt="koinpr logo"
                />
              </a>
              <button onClick={onClose} className="text-[#000000]">
                <RiCloseLine className="w-6 h-6" />
              </button>
            </div>
            <div className="flex gap-[14px] bg-[#F9F9F9] py-[15px] px-4">
              {
                user && <div className="w-[40px] h-[40px] bg-[#3053A6] rounded-full flex items-center justify-center text-white text-lg">
                  {user?.fullname?.charAt(0).toUpperCase()}
                </div>
              }
              <div>
                <p className="font-[510] text-[16px] leading-[19.2px] text-[#0A0A0A] !mb-0">
                  {user?.fullname || "Hello Guest"}
                </p>
                <span className="font-[400] capitalize text-[13px] leading-[19.5px] text-[#787878]">
                  {user?.userType || "Sign In/Up to Place Order "}
                </span>
              </div>
            </div>
            <div className="px-4 my-[26px]">
              <div className="suggest_btn shadow-[0px_4px_28px_#0000001C]">
                <button className="font-[590] text-[13px] leading-[19.2px] text-[#000000] flex items-center gap-2 px-[20px] py-[10px]">
                  <img src="/assets/images/carousel/group.png" alt="" /> Hire
                  Conversions Expert
                </button>
              </div>
            </div>
            <ul className="mt-4 px-4">
              {user && (<li className="mb-[20px]">
                <Link
                  className="font-[510] text-[13px] leading-[15.6px] text-[#434343] no-underline flex gap-3 items-center"
                  to="#"
                >
                  {/* <PiUserCircleFill className="w-[24px] h-[24px] text-[#434343]" />{" "} */}
                  <img
                    src="/assets/images/MarketPlace/icons-1.svg"
                    alt="account"
                    className="text-[#434343]"
                  />
                  My Account{" "}
                  {/* <IoMdAlert className="w-[16px] h-[16px] text-[#eb5757]" /> */}
                </Link>
              </li>
              )}

              {user &&
                <li className="mb-[20px]">
                  <Link
                    className="font-[510] text-[13px] leading-[15.6px] text-[#434343] no-underline flex gap-3 items-center"
                    to="/my-cart"
                  >
                    <img
                      src="/assets/images/MarketPlace/icons-2.svg"
                      alt="account"
                      className="text-[#434343]"
                    />
                    My Cart
                  </Link>
                </li>
              }

              {/* <li className="mb-[20px]">
                <Link
                  className="font-[510] text-[13px] leading-[15.6px] text-[#434343] no-underline flex gap-2 items-center"
                  to="/Package"
                >
                  <IoCartOutline className="w-[24px] h-[24px] text-[#434343]" />{" "}
                  Package
                </Link>
              </li> */}
              <li className="mb-[20px]">
                <Link
                  className="font-[510] text-[13px] leading-[15.6px] text-[#434343] no-underline flex gap-3 items-center"
                  to="/Marketplace"
                >
                  <img
                    src="/assets/images/MarketPlace/icons-3.svg"
                    alt="account"
                    className="text-[#434343]"
                  />
                  Marketplace
                </Link>
              </li>
              <li className="mb-[20px]">
                <Link
                  target="_blank"
                  className="font-[510] text-[13px] leading-[15.6px] no-underline flex gap-3 items-center"
                  to="https://koinpr.com/koinpr-eod"
                >
                  <img
                    src="/assets/images/MarketPlace/icons-4.svg"
                    alt="account"
                    className="text-[#434343]"
                  />
                  <span className="text-gradient">Koinpr EOD.</span>
                </Link>
              </li>
              <li className="mb-[20px]">
                <Link
                  target="_blank"
                  className="font-[510] text-[13px] leading-[15.6px] text-[#434343] no-underline flex gap-3 items-center"
                  to="https://koinpr.com/koinpr-one"
                >
                  <img
                    src="/assets/images/MarketPlace/icons-5.svg"
                    alt="account"
                    className="text-[#434343]"
                  />
                  <span className="text-gradient">Koinpr One</span>
                </Link>
              </li>
              <li className="mb-[20px]">
                <Link
                  className="font-[510] text-[13px] leading-[15.6px] text-[#434343] no-underline flex gap-3 items-center"
                  to="/Package"
                >
                  {/* <img
                    src="/assets/images/MarketPlace/icons-6.svg"
                    alt="account"
                    className="text-[#434343]"
                  /> */}
                  <PiPackageFill className="text-[#434343] text-xl" />
                  Packages
                </Link>
              </li>
              <li className="mb-[20px]">
                <Link
                  target="_blank"
                  className="font-[510] text-[13px] leading-[15.6px] text-[#434343] no-underline flex gap-3 items-center"
                  to="https://koinpr.com/contact"
                >
                  <img
                    src="/assets/images/MarketPlace/icons-7.svg"
                    alt="account"
                    className="text-[#434343]"
                  />
                  Contact Us
                </Link>
              </li>
              <li className="mb-[20px]">
                <Link
                  target="_blank"
                  className="font-[510] text-[13px] leading-[15.6px] text-[#434343] no-underline flex gap-3 items-center"
                  to="https://koinpr.com/about"
                >
                  <img
                    src="/assets/images/MarketPlace/icons-6.svg"
                    alt="account"
                    className="text-[#434343]"
                  />
                  About Us
                </Link>
              </li>
              {/* <li className="mb-3">
                <Link
                  className="font-[510] text-[13px] leading-[15.6px] text-[#434343] no-underline flex gap-3 items-center"
                  to="#"
                >
                  <CiBookmark className="w-[24px] h-[24px] text-[#434343]" /> My
                  Bookmarks
                </Link>
              </li> */}
              {/* <li className="mb-3">
                <Link
                  className="font-[510] text-[13px] leading-[15.6px] text-[#434343] no-underline flex gap-2 items-center"
                  to="#"
                >
                  <RxCounterClockwiseClock className="w-[24px] h-[24px] text-[#434343]" />
                  Order History
                </Link> */}
              {/* </li> */}
            </ul>
          </div>
          <div>
            <div className="border-b-[1px] border-[#DBDBDB] my-[19px]"></div>
            <div className="px-4 pb-4">
              {user ? (
                <div
                  className="font-[510] text-[13px] leading-[15.6px] text-[#434343] flex gap-[6.5px]  cursor-pointer items-center px-4 mb-[19px]"
                  onClick={handleLogout}
                >
                  <MdLogout className="text-[#434343] w-[18px] h-[18px] cursor-pointer" />
                  Log out
                </div>
              ) :

                <button
                  className="cursor-pointer hover:bg-transparent hover:!text-black flex items-center gap-3 duration-200 bg-[#413ada] border border-black text-white text-[16px] rounded-lg py-2 px-3 "
                  onClick={() => {
                    navigate("/sign-up");
                  }}
                >
                  Sign Up <FaArrowRight />
                </button>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
