import {
  REGISTER,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  OTP_VERIFY_REQUEST,
  OTP_VERIFY_SUCCESS,
  OTP_VERIFY_FAILURE,
  UPDATE_FORM_DATA,
  RESET_FORM_DATA,
  SUBMIT_FORM_REQUEST,
  SUBMIT_FORM_SUCCESS,
  SUBMIT_FORM_FAILURE,
  LOGOUT,
  FETCH_USER_DATA_REQUEST,
  FETCH_USER_DATA_SUCCESS,
  FETCH_USER_DATA_FAILURE,
  FORGET_PASSWORD_REQUEST,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
} from "../actions/auth.action";

const initialState = {
  registor: "",
  loading: false,
  userType: null,
  user: null,
  email: null,
  files: [],
  formData: {},
  error: null,
  isEmailVerified: false,
  isAuthenticated: !!localStorage.getItem("accessToken"),
  passwordResetEmailSent: false,
  passwordResetSuccess: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER:
      return {
        ...state,
        register: action.payload,
        user: action.payload.user,
        email: action.payload.email,
        userType: action.payload.userType,
      };
    case REGISTER_REQUEST:
    case LOGIN_REQUEST:
    case OTP_VERIFY_REQUEST:
    case FORGET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case REGISTER_SUCCESS:
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.user,
        email: action.payload.email,
        userType: action.payload.userType,
        isEmailVerified: action.payload.user.isEmailVerified,
        isAuthenticated: true,
      };
    case OTP_VERIFY_SUCCESS:
      return {
        ...state,
        loading: false,
        isEmailVerified: true,
      };

    case FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        passwordResetEmailSent: true,
        error: null,
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        passwordResetSuccess: true,
      };
    case REGISTER_FAILURE:
    case LOGIN_FAILURE:
    case OTP_VERIFY_FAILURE:
    case FORGET_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        passwordResetEmailSent: false,
      };
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        passwordResetSuccess: false,
      };
    case SUBMIT_FORM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case UPDATE_FORM_DATA:
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.payload,
        },
      };
    case RESET_FORM_DATA:
      return {
        ...state,
        formData: {},
      };
    case SUBMIT_FORM_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUBMIT_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        email: null,
        userType: null,
        isEmailVerified: false,
      };
    case FETCH_USER_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_USER_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.user,
        isEmailVerified: action.payload.user.isEmailVerified,
      };
    case FETCH_USER_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default authReducer;
