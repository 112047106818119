import React, { useEffect } from "react";
import { PiArrowSquareOutLight } from "react-icons/pi";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
import { IoCartOutline } from "react-icons/io5";
// import { IoCartOutline } from "react-icons/io5";
// import { Link } from "react-router-dom";

const BannerExample = ({ idx, features, state, addToCart, isAdvertiser }) => {
  //   const handleAddToCart = async (addOn) => {
  //     try {
  //       await addToCart("addOns", addOn);
  //     } catch (error) {
  //       console.error("Error adding to cart:", error);
  //     }

  return (
    <div
      className="offcanvas example !h-[80vh] px-md-5 offcanvas-bottom rounded-[24px_24px_0_0]"
      tabindex="-1"
      id={`offcanvasBottom${idx}`}
      aria-labelledby="offcanvasBottomLabel"
    >
      <div className="offcanvas-header">
        <button
          type="button"
          className="btn-close shadow-none pt-4 text-black text-[14px]"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body small">
        <h5
          className="offcanvas-title text-center mb-5 mx-auto font-[590] md:text-[30px] text-[20px]"
          id="offcanvasBottomLabel"
        >
          Example
        </h5>
        {state.example && state?.example?.links?.length > 0 ? (
          <div className="row gy-4">
            <div className="col-lg-6 order-2 order-lg-1 ">
              <table className="table table-bordered h-10 rounded table-striped">
                {state.example && state?.example?.links?.length > 0 && (
                  <tbody>
                    {state.example.links.map((link, index) => (
                      <tr key={index}>
                        <th scope="row" className="w-[200px]">
                          {link?.title}
                        </th>
                        <td>
                          <span className="text-[13px] font-[510] flex text-[#5E5E5E] ps-4 items-center gap-1">
                            {link?.value}
                            <PiArrowSquareOutLight
                              onClick={() => {
                                window.open(
                                  link?.value.startsWith("http")
                                    ? link?.value
                                    : `http://${link?.value}`,
                                  "_blank"
                                );
                              }}
                              className="text-[#3772FF] cursor-pointer"
                            />
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
            <div className="col-lg-6 order-1  mx-auto order-lg-2">
              <OwlCarousel className="owl-theme" loop margin={10} items={1}>
                {state.example && state?.example?.screenshots.length > 0 ? (
                  state?.example?.screenshots.map((screenshot, index) => (
                    <div key={index} className="item">
                      <img
                        src={`${process.env.REACT_APP_ASSET_URL}/${screenshot}`}
                        alt={screenshot}
                        className="max-w-[500px] max-h-[500px]"
                      />
                    </div>
                  ))
                ) : (
                  <p className="text-center mx-auto font-[510] col-lg-7 bg-[#F2DDDD] py-3 rounded-lg">
                    No image provided by partner
                  </p>
                )}
              </OwlCarousel>
              <div className="row">
                {isAdvertiser && (
                  <div
                    className="col-12 text-center cursor-pointer"
                    onClick={() => {
                      addToCart(state);
                    }}
                  >
                    <p className="mt-5  text-black font-[510] border-[1px] border-black no-underline inline-flex items-center px-[16px] py-[10px] rounded gap-2">
                      <IoCartOutline className="text-[24px]" />
                      Add to cart &nbsp; $
                      
                      {state?.livePrice > 0
                                    ? state?.livePrice
                                    : state?.mediaDiscountedKitPrice > 0
                                    ? state?.mediaDiscountedKitPrice 
                                    : state?.mediaKitprice || 0}
                      {/* {state?.livePrice > 0
                        ? state?.livePrice
                        : state?.mediaKitprice} */}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <p className="text-center mx-auto font-[510] col-lg-7 bg-[#F2DDDD] py-3 rounded-lg">
            No image provided by partner
          </p>
        )}

        {/* <div className="row justify-center mt-4 text-center ">
          <div className="col-lg-4">
            <h4 className="md:text-xl text-[16px] font-[590] mb-5 ">
              The banner will be visible on all the domains (EN, ES, DE, RU, and
              ID)
            </h4>
            <p className="mb-2">
              <span className="font-[590]">Device: </span>
              <span>Desktop: 728x90 | Mobile: 320x50</span>
            </p>
            <p className="mb-0">
              <span className="font-[590]">Duration: </span>
              <span>1 month</span>
            </p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default BannerExample;
