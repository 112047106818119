import React from "react";

const SkeletonLoader = () => {
  return (
    <div className="animate-pulse bg-white mb-4 overflow-hidden shadows rounded-lg border-1 border-[#DBDBDB]">
      <div className="p-md-4 p-3">
        <div className="flex items-center justify-between mb-3">
          <div className="flex items-center gap-2">
            <div className="bg-gray-300 rounded-full h-10 w-10"></div>
            <div className="bg-gray-300 rounded h-6 w-32"></div>
          </div>
          <div className="bg-gray-300 rounded h-6 w-16"></div>
         
        </div>
        <div className="bg-gray-300 rounded h-8 w-1/2 mb-3"></div>
        <div className="bg-gray-300 rounded h-8 w-1/2 mb-3"></div>
        <div className="bg-gray-300 rounded h-8 w-1/2 mb-3"></div>
        <div className="bg-gray-300 rounded h-8 w-1/2 mb-3"></div>
        <div className="bg-gray-300 rounded h-8 w-1/2 mb-3"></div>
        <div className="bg-gray-300 rounded h-8 w-1/2 mb-3"></div>
        
        <div className="bg-gray-300 rounded h-4 w-2/4"></div>
      </div>
      <div className="!bg-[#F5F5F5] px-4 card-footer border-0 p-2 d-flex justify-between items-center">
        <div className="bg-gray-300 rounded h-4 w-24"></div>
        <div className="bg-gray-300 rounded h-4 w-16"></div>
      </div>
    </div>
  );
};

export default SkeletonLoader;
