import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../../redux/actions/auth.action";

const Index = () => {
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const dispatch = useDispatch();
  const { loading, error, isEmailVerified, user } = useSelector(
    (state) => state.authentication
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      if (!isEmailVerified) {
        navigate("/Marketplace");
      } else {
        if (user.userType === "publisher") navigate("/dashboard");
        else navigate("/Marketplace");
      }
    }
  }, [user, isEmailVerified, navigate]);

  const handleChange = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!credentials.email || !credentials.password) {
      return;
    }
    dispatch(login(credentials));
  };

  return (
    <div className="sign-up">
      <div className="d-md-flex d-none h-screen">
        <div className="col-xl-5 col-4 d-lg-block d-none">
          <img
            src="/assets/images/signup/sideframe.png"
            className="h-full w-full object-cover"
            alt="slide_frame"
          />
        </div>
        <div className="col-xl-6 col-lg-8">
          <div className="py-[50px] px-[130px] h-full flex justify-center items-center">
            <div className="col-10">
              <h3 className="text-[24px]">Sign In</h3>
              <p className="mb-5">
                Don’t have an account?{" "}
                <Link to="/Sign-up" className="blue-color underline-offset-4">
                  Sign up
                </Link>
              </p>
              <form onSubmit={handleSubmit}>
                <div className="row gy-4">
                  <div className="col-12">
                    <div>
                      <input
                        type="email"
                        name="email"
                        value={credentials.email}
                        onChange={handleChange}
                        placeholder="Email ID"
                        className="sign-input"
                      />
                      {error?.email && (
                        <p className="text-danger">{error.email}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-12">
                    <div>
                      <input
                        type="password"
                        name="password"
                        value={credentials.password}
                        onChange={handleChange}
                        placeholder="Password"
                        className="sign-input"
                      />
                      {error?.password && (
                        <p className="text-danger">{error.password}</p>
                      )}
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="bg-black-color btn_hover duration-200 block no-underline text-center leading-[46px] px-[20px] text-white rounded-lg w-full h-[46px] mt-[24px]"
                >
                  {loading ? "Signing in..." : "Sign In"}
                </button>
                {error && typeof error === "string" && (
                  <p className="text-danger ">{error}</p>
                )}

                <Link
                  to="/forget-pass"
                  className="text-black no-underline mt-3 inline-block"
                >
                  Forget Password?
                </Link>
               
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="d-block d-md-none h-screen flex items-center justify-center">
        <div className="w-full px-4">
          <h3 className="text-[24px] text-center">Sign In</h3>
          <p className="mb-5 text-center">
            Don’t have an account?{" "}
            <Link to="/Sign-up" className="blue-color underline-offset-4">
              Sign up
            </Link>
          </p>
          <form onSubmit={handleSubmit}>
            <div className="row gy-4">
              <div className="col-12">
                <input
                  type="email"
                  name="email"
                  value={credentials.email}
                  onChange={handleChange}
                  placeholder="Email ID"
                  className="sign-input"
                />
                {error?.email && <p className="text-danger">{error.email}</p>}
              </div>
              <div className="col-12">
                <input
                  type="password"
                  name="password"
                  value={credentials.password}
                  onChange={handleChange}
                  placeholder="Password"
                  className="sign-input"
                />
                {error?.password && (
                  <p className="text-danger">{error.password}</p>
                )}
              </div>
            </div>
            <button
              type="submit"
              className="bg-black-color btn_hover duration-200 block no-underline text-center leading-[46px] px-[20px] text-white rounded-lg w-full h-[46px] mt-[24px]"
            >
              {loading ? "Signing in..." : "Sign In"}
            </button>
            {error && typeof error === "string" && (
              <p className="text-danger ">{error}</p>
            )}

            <Link
              to="/forget-pass"
              className="text-black no-underline mt-3 inline-block"
            >
              Forget Password?
            </Link>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Index;
