// redux/actions/filter.actions.js

export const SET_FILTER = "SET_FILTER";
export const RESET_FILTER = "RESET_FILTER";
export const TOGGLE_ARRAY_FILTER = "TOGGLE_ARRAY_FILTER"; // For handling array updates
export const SET_SELECT_FILTER = "SET_SELECT_FILTER";
export const RESET_ALL_FILTERS = "RESET_ALL_FILTERS";


export const setFilter = (filterType, value) => ({
  type: SET_FILTER,
  payload: { filterType, value },
});

export const resetFilter = (filterType) => ({
  type: RESET_FILTER,
  payload: filterType,
});

export const toggleArrayFilter = (filterType, value) => ({
  type: TOGGLE_ARRAY_FILTER,
  payload: { filterType, value },
});

export const setSelectFilter = (key, value) => ({
  type: SET_SELECT_FILTER,
  payload: { key, value },
});

export const resetAllFilters = () => ({
  type: RESET_ALL_FILTERS,
});
