import React, { useCallback, useEffect, useState } from "react";
import { FaCheckCircle, FaRegEye } from "react-icons/fa";
import { TbWorld } from "react-icons/tb";
import MarketplaceBrand from "./MarketplaceBrand.jsx";
import MarketplaceSideBar from "./MarketplaceSidebar.jsx";
import MarketplaceHeader from "./MarketPlaceHeader.jsx";
import { FaPlusSquare } from "react-icons/fa";
import VerifyEmail from "../expanded-view/VerifyEmail.js";
import useCategories from "../../hooks/useCategories.js";
import {
  Link,
  useLocation,
  useNavigate,
  useNavigationType,
} from "react-router-dom";
import useAddCart from "../../hooks/useAddCart.js";
import { authApi } from "../../api/index.js";
import { useSelector, useDispatch } from "react-redux";
import { fetchUserData } from "../../redux/actions/auth.action";
import Dropdown from "react-bootstrap/Dropdown";
import { BsCart3 } from "react-icons/bs";
import { IoCartOutline } from "react-icons/io5";
import { fetchCartData } from "../../redux/actions/cart.actions.js";
import { Form } from "react-bootstrap";
import { formateNumber } from "../common/formateNumber.js";
import {
  resetAllFilters,
  setFilter,
  setSelectFilter,
} from "../../redux/actions/filter.action.js";

export default function PublisherDashboard() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigationType = useNavigationType();

  const { filters, selectFilter } = useSelector((state) => state.filters);

  console.log(
    "consoling flter",
    // filters.region,
    filters.categories,
    // filters.budget,
    // filters.allowedContent,
    selectFilter
  );

  const { categories } = useCategories();
  const { addToCart, loading } = useAddCart();
  const [offerings, setOfferings] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState(filters.categories || []);

  const [filteredOfferings, setFilteredOfferings] = useState([]);
  const [isSortedByKPR, setIsSortedByKPR] = useState(false);
  const [expandedCategories, setExpandedCategories] = useState({});
  const [noData, setNoData] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(
    filters.region || []
  );

  const [selectedPrice, setSelectedPrice] = useState(filters.budget || null);
  const [selectedFilter, setSelectedFilter] = useState(
    filters.allowedContent || []
  );
  const [showFilter, setshowFilter] = useState(false);
  const [isAdvertiser, setIsAdvertiser] = useState(false);
  const [filterDropDown, setFilterDropDown] = useState( selectFilter || "");
  const [search, setSearch] = useState("");
  const cartItems = useSelector((state) => state.cart.cartItems);
  
  useEffect(() => {
    setFilterDropDown(selectFilter);
  }, [selectFilter]);
  

  useEffect(() => {
    dispatch(fetchUserData());
  }, [dispatch]);

  useEffect(() => {
    if (navigationType === "PUSH" && !location.state) {
      dispatch(resetAllFilters());
    }
  }, [navigationType, location.state, dispatch]);

  useEffect(() => {
    setSelectedCategories(
      Array.isArray(filters.categories)
        ? filters.categories
        : [filters.categories]
    );
  }, [filters]);

  const { user, isEmailVerified } = useSelector(
    (state) => state.authentication
  );

  const isAuthenticated = useSelector(
    (state) => state.authentication.isAuthenticated
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (!user?.userType) {
      return;
    }

    if (user?.userType === "publisher") setIsAdvertiser(false);
    else setIsAdvertiser(true);
  }, [isAdvertiser, user]);

  const fetchOffering = async () => {
    try {
      const response = await authApi.get(`offerings/`, {
        params: {
          status: "approved",
        },
      });
      setOfferings(response?.data?.offeringList || []);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchOffering();
  }, []);

  const setInitialFilterDropdown = () => {

    if (Object.keys(filterDropDown).length > 0) {
      return; 
    }
    // dispatch(resetAllFilters());
    const selectedItem = seeAllfilters?.find(
      (i) => i.id === selectedCategories[0]
    )?.options;

    if (selectedItem?.length > 0) {
      const firstOption = selectedItem[0];
      const firstKey = Object.keys(firstOption)[0];


      if (firstKey) {
        const firstValue = firstOption[firstKey]?.[0];

        if (firstValue) {
          setFilterDropDown((prev) => ({
            [firstKey]: firstValue,
          }));
          dispatch(setSelectFilter(firstKey, firstValue));
        }
      }
    }
  };

  useEffect(() => {
    setInitialFilterDropdown();
  }, [selectedCategories]);

  const handleResetSubCategory = () => {
    setInitialFilterDropdown();
  };

  useEffect(() => {
    let sortedOfferings = offerings;

    if (selectedCategories.length > 0) {
      sortedOfferings = offerings.filter((offering) =>
        selectedCategories.includes(offering?.offeringCategory?.name)
      );
    }
    if (selectedLanguage.length > 0) {
      sortedOfferings = sortedOfferings.filter((offering) =>
        selectedLanguage.some(
          (language) =>
            offering?.basicDetails?.contentLanguage?.toLowerCase() ===
            language.toLowerCase()
        )
      );
    }

    if (selectedPrice) {
      let minPrice = 0;
      let maxPrice = Infinity;

      if (selectedPrice === "$5000+") {
        minPrice = 5000;
        maxPrice = Infinity;
      } else {
        const [minPriceStr, maxPriceStr] = selectedPrice
          .replace(/\$/g, "")
          .split("-")
          .map(Number);

        minPrice = minPriceStr || 0;
        maxPrice = maxPriceStr || Infinity;
      }

      sortedOfferings = sortedOfferings
        ?.filter((offering) => {
          const price = offering?.contentOfferings
            ?.map((contentOffering) => contentOffering?.mediaKitprice)
            ?.reduce((a, b) => Math.min(a, b), Infinity);

          return price >= minPrice && price <= maxPrice;
        })
        .map((offering) => {
          const matchingContentOffering = offering?.contentOfferings?.find(
            (contentOffering) => {
              const price = contentOffering?.mediaKitprice;
              return price >= minPrice && price <= maxPrice;
            }
          );

          // Set targetId as the id of the matching contentOffering
          const targetId = matchingContentOffering?._id || null;

          // Return the offering with the targetId
          return { ...offering, targetId };
        });
    }

    if (selectedFilter.length > 0) {
      sortedOfferings = sortedOfferings.filter((offering) => {
        let matchesFilter = false;

        if (
          selectedFilter.includes("gambling") &&
          offering?.basicDetails?.gambling === "yes"
        ) {
          matchesFilter = true;
        }
        if (
          selectedFilter.includes("crypto") &&
          offering?.basicDetails?.crypto === "yes"
        ) {
          matchesFilter = true;
        }
        if (
          selectedFilter.includes("adultContent") &&
          offering?.basicDetails?.adultContent === "yes"
        ) {
          matchesFilter = true;
        }

        return matchesFilter;
      });
    }

    if (isSortedByKPR) {
      sortedOfferings = sortedOfferings.sort(
        (a, b) => b?.kprMetric - a?.kprMetric
      );
    } else {
      sortedOfferings = sortedOfferings.sort(
        (a, b) => a?.userId?.priority - a?.userId?.priority
      );
    }

    setFilteredOfferings(sortedOfferings);

    if (filterDropDown) {

      console.log("filterDropDown----->>>>", filterDropDown);
      let furtherFiltered = sortedOfferings;
      Object.keys(filterDropDown).forEach((filter) => {
        if (
          filter === "Article Type" ||
          filter === "Advertisment Type" ||
          filter === "Post Type" ||
          filter === "Profile Topics" ||
          filter === "Video Type" ||
          filter === "Profile Topics"
        ) {
          furtherFiltered = furtherFiltered.filter((offering) => {
            return offering?.contentOfferings?.some(
              (contentOffering) =>
                contentOffering?.typeId?.name === filterDropDown[filter]
            );
          });
        } else if (filter === "Link Type" || filter === "Social Share") {
          furtherFiltered = furtherFiltered.filter((offering) => {
            return offering?.contentOfferings?.some((contentOffering) => {
              // console.log(filterDropDown[filter])
              if (filter === "Link Type" && filterDropDown[filter] === "Do Follow") {
                return contentOffering?.features.includes("link type");
              } else if (
                filter === "Link Type" &&
                filterDropDown[filter] === "No Follow"
              ) {
                return !contentOffering?.features.includes("link type");
              } else if (
                filter === "Social Share" &&
                filterDropDown[filter] === "Yes"
              ) {
                return contentOffering?.features.includes("social share");
              } else if (
                filter === "Social Share" &&
                filterDropDown[filter] === "No"
              ) {
                return !contentOffering?.features.includes("social share");
              }
              return false;
            });
          });
        } else if (filter === "Channel Topic") {
          furtherFiltered = furtherFiltered.filter((offering) => {
            return offering?.basicDetails?.channelTopic?.some((topic) =>
              filterDropDown[filter].includes(topic)
            );
          });
        } else if (filter === "Bases") {
          furtherFiltered = furtherFiltered.filter((offering) => {
            return offering?.contentOfferings?.some(
              (contentOffering) =>
                contentOffering?.bases === filterDropDown[filter]
            );
          });
        }
      });
      setFilteredOfferings(furtherFiltered);
    }

    if (search) {
      const searchResult = sortedOfferings?.filter((offering) =>
        offering?.basicDetails?.websiteName
          ?.toLowerCase()
          .includes(search.toLowerCase())
      );
      setFilteredOfferings(searchResult);
    }

    const hasData = sortedOfferings.length > 0;

    setNoData(!hasData);
  }, [
    selectedCategories,
    selectedLanguage,
    selectedPrice,
    selectedFilter,
    offerings,
    isSortedByKPR,
    search,
    filterDropDown,
    dispatch
  ]);

  const handleFilter = (filter) => {
    setSelectedFilter((prev) => {
      const updatedFilters = prev.includes(filter)
        ? prev.filter((fil) => fil !== filter)
        : [...prev, filter];

      dispatch(setFilter("allowedContent", updatedFilters));

      return updatedFilters;
    });
  };

  const checkItemExistInCart = useCallback(
    (itemId) => {
      if (!cartItems) return false;
      if (!itemId) return false;
      // console.log({ cartItems });

      const allItems = [...(cartItems.addOn || [])];

      return allItems.some((item) => {
        // console.log({ item: item._id === itemId });
        return (
          item._id === itemId || item.id === itemId || item.id?._id === itemId
        );
      });
    },
    [cartItems]
  );

  const handleCategorySelect = (category, type = false) => {

    dispatch(resetAllFilters());
    if (!type) {
      setExpandedCategories((prev) => ({
        ...prev,
        [category]: true,
      }));
      setshowFilter(true);
      setSelectedCategories([category]);
      dispatch(setFilter("categories", category));
     
    } else {
      setSelectedCategories([category]);
      setExpandedCategories((prev) => ({
        ...prev,
        [category]: true,
      }));
    }
  };

  const handleSortToggle = (checked) => {
    setIsSortedByKPR(checked);
  };

  const handleToggle = (category) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
    setSelectedCategories([]);
  };

  const handleAddToCart = async (itemId) => {
    try {
      await addToCart("addOns", itemId);
      dispatch(fetchCartData());
    } catch (error) {
      console.error("Error adding to cart:", error);
    }
  };

  const seeAllfilters = [
    {
      id: "Content Distribution",
      options: [
        {
          "Article Type": [
            "Press Release",
            "Sponsored",
            "Organic",
            "Review",
            "Listicle",
            "AMA",
            "Interview/Project Intro",
            "Price Analysis",
          ],
        },
        { "Link Type": ["Do Follow", "No Follow"] },
        { "Social Share": ["Yes", "No"] },
      ],
    },
    {
      id: "Ads",
      options: [
        {
          "Advertisment Type": [
            "Trending Coins",
            "Footer Banner",
            "Post Page In Article Banner",
            "Article Page Sidebar Banner",
            "Article Page Banner",
            "Header Button",
            "Header Banner",
          ],
        },
        { Bases: ["Fixed", "cpm"] },
      ],
    },
    {
      id: "Twitter Influencers",
      options: [
        {
          "Post Type": [
            "Thread Tweet",
            "Quote Tweet",
            "Giveaway/Airdrop",
            "Retweet",
            "Tweet",
          ],
        },
        {
          "Channel Topic": [
            "Trading",
            "NFT",
            "DeFi",
            "Crypto/Web3",
            "Blockchain",
            "Giveaways",
            "Project Introduction",
            "ICOs",
            "Project Review",
          ],
        },
      ],
    },
    {
      id: "Telegram Influencers",
      options: [
        { "Post Type": ["AMA", "Post"] },
        {
          "Channel Topic": [
            "Trading",
            "NFT",
            "DeFi",
            "Crypto/Web3",
            "Blockchain",
            "Giveaways/Airdrops",
            "ICOs",
            "Project Introduction",
            "Project `Review`",
            "AMA",
          ],
        },
      ],
    },
    {
      id: "Youtube Influencers",
      options: [
        {
          "Video Type": [
            "Shoutout",
            "Airdrop Announcement",
            "AMA/Interview",
            "Integration",
            "Video Review",
          ],
        },
        {
          "Channel Topic": [
            "Trading",
            "NFT",
            "DeFi",
            "Crypto/Web3",
            "Blockchain",
            "Giveaways/Airdrops",
            "ICOs",
            "Cloud Mining",
            "Project Introduction",
            "Project Review",
            "AMA/Interview",
          ],
        },
      ],
    },
    {
      id: "Instagram Influencers",
      options: [
        { "Post Type": ["Permanent", "24 hours", "7 Days"] },
        {
          "Profile Topics": [
            "Reel",
            "Video Story",
            "Static Story",
            "Video Post",
            "Static Post",
          ],
        },
      ],
    },
    {
      id: "TikTok Influencers",
      options: [
        {
          "Profile Topics": [
            "Trading",
            "NFT",
            "DeFi",
            "Crypto/Web3",
            "Blockchain",
            "Giveaways/Airdrops",
            "ICOs",
            "Cloud Mining",
            "Project Introduction",
            "Project Review",
            "AMA/Interview",
            "Business",
          ],
        },
      ],
    },
    {
      id: "ICO Listing",
      options: [
        // {
        //   "Article Type": [
        //     "Sponsored",
        //     "Organic",
        //     "Review",
        //     "Listicle",
        //     "AMA",
        //     "Interview/Project Intro",
        //     "Price Analysis",
        //   ],
        // },
        // { "Link Type": ["Yes", "No"] },
        // { "Social Share": ["Yes", "No"] },
      ],
    },
  ];

  function formatNumber(value) {
    if (!value && value !== 0) return "0"; // Handle undefined or null
    if (value >= 1e9) return (value / 1e9).toFixed(1).replace(/\.0$/, "") + "B";
    if (value >= 1e6) return (value / 1e6).toFixed(1).replace(/\.0$/, "") + "M";
    if (value >= 1e3) return (value / 1e3).toFixed(1).replace(/\.0$/, "") + "K";
    return value.toString();
  }

  const countOfFilteredOfferings = filteredOfferings.length;
  return (
    <>
      {user && !isEmailVerified && <VerifyEmail />}

      <div className="md:px-[32px] px-[12px] py-[24px]">
        <div className="row">
          <div className="col-xl-2 col-lg-3 d-lg-block d-none">
            <MarketplaceSideBar
              selectedLanguage={selectedLanguage}
              setSelectedLanguage={setSelectedLanguage}
              categories={categories}
              onCategorySelect={handleCategorySelect}
              selectedCategories={selectedCategories}
              selectedPrice={selectedPrice}
              setSelectedPrice={setSelectedPrice}
              selectedFilter={selectedFilter}
              setSelectedFilter={setSelectedFilter}
              handleFilter={handleFilter}
              setFilterDropDown={setFilterDropDown}
              seeAllfilters={seeAllfilters}
            />
          </div>
          <div className="col-xl-10 col-lg-9">
            <div className="row gy-4">
              <div className="col-12">
                <MarketplaceHeader
                  onSortToggle={handleSortToggle}
                  count={countOfFilteredOfferings}
                  onCategorySelect={handleCategorySelect}
                  selectedCategories={selectedCategories}
                  setSelectedLanguage={setSelectedLanguage}
                  categories={categories}
                  noData={noData}
                  search={search}
                  setSearch={setSearch}
                  isAdvertiser={isAdvertiser}
                  seeAllfilters={seeAllfilters}
                  setFilterDropDown={setFilterDropDown}
                  filterDropDown={filterDropDown}
                  handleResetSubCategory={handleResetSubCategory}
                  setFilteredOfferings={setFilteredOfferings}
                  setExpandedCategories={setExpandedCategories}
                  setSelectedCategories={setSelectedCategories}
                  // onReset={onReset}
                />
              </div>
              {(showFilter || !noData) && (
                <div className="!mt-[50px]">
                  <div className="filter-btn d-lg-block d-none gap-[20px]">
                    {seeAllfilters
                      .filter(
                        (filter) =>
                          filter.id.toLowerCase() ===
                          selectedCategories[0]?.toLowerCase()
                      )
                      .map((filter) => (
                        <div
                          key={filter.id}
                          className="flex justify-between items-end"
                        >
                          <div className="d-flex items-end gap-3">
                            {filter.options.map((option, optionIndex) =>
                              Object.keys(option).map((key) => {
                                return (
                                  <div
                                    key={`${key}-${optionIndex}`}
                                    className="dropdown-container"
                                  >
                                    <label
                                      htmlFor={`dropdown-basic-${key}-${optionIndex}`}
                                      className="block mb-2 !font-[510] shadow-none capitalize !text-[16px] !leading-[19.2px] !text-[#000000]"
                                    >
                                      {`${key}`}
                                    </label>

                                    <Dropdown className="inline-block">
                                      <Dropdown.Toggle
                                        className="!font-[400] shadow-none capitalize !text-[16px] !leading-[19.2px] !text-gray-700 firstFilter"
                                        id={`dropdown-basic-${key}-${optionIndex}`}
                                      >
                                        {filterDropDown[key] || `Select ${key}`}
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu className="w-full">
                                        {option[key]?.map((item, itemIndex) => (
                                          <Dropdown.Item
                                            key={`${item}-${itemIndex}`}
                                            onClick={() => {
                                              setFilterDropDown((prev) => ({
                                                ...prev,
                                                [key]: item,
                                              }));
                                              dispatch(
                                                setSelectFilter([key], item)
                                              );
                                            }}
                                          >
                                            {item}
                                          </Dropdown.Item>
                                        ))}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                );
                              })
                            )}
                            <div className="suggest_btn">
                              <button className="font-[500] text-[16px] bg-white rounded-lg leading-[19.2px] text-[#000000] flex items-center gap-8 px-[20px] py-[15px]">
                                KPR Metric
                                <Form>
                                  <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    checked={isSortedByKPR}
                                    onChange={(e) =>
                                      handleSortToggle(e.target.checked)
                                    }
                                    className="accent-black"
                                  />
                                </Form>
                              </button>
                            </div>
                          </div>
                          <div className="filter-btn mt-4 self-end">
                            <p
                              className="!font-[510] cursor-pointer shadow-none mb-0 !text-[15px] !leading-[19.2px] !text-[#666666]"
                              onClick={() => handleResetSubCategory()}
                            >
                              Reset Filters
                            </p>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              )}
              <div className="col-lg-8 xl:!w-[70%] md:!w-[60%] !w-full">
                {noData ? (
                  <p>No data present here</p>
                ) : (
                  categories
                    ?.filter((category) =>
                      filteredOfferings?.some(
                        (offering) =>
                          offering?.offeringCategory?.name === category?.name
                      )
                    )
                    ?.map((category, index) => {
                      const categoryOfferings = filteredOfferings.filter(
                        (offering) =>
                          offering?.offeringCategory?.name === category?.name
                      );

                      const isExpanded = expandedCategories[category?.name];
                      const offeringsToDisplay = isExpanded
                        ? categoryOfferings
                        : categoryOfferings.slice(0, 3);
                      console.log("offeringsToDisplay", offeringsToDisplay);

                      return (
                        <div
                          key={index}
                          className={`${index === 0 ? "mt-0" : "mt-4"}`}
                        >
                          <div className="flex justify-between items-center">
                            <p className="font-[590] text-[20px]">
                              {category?.name}
                            </p>
                            {(categoryOfferings.length > 3 ||
                              filterDropDown) && (
                              <button
                                className="text-base font-[510px] text-[#434343]"
                                onClick={() => {
                                  handleToggle(category?.name);
                                  if (isExpanded) setshowFilter(false);

                                  if (!isExpanded || filterDropDown) {
                                    setshowFilter(true);
                                    handleCategorySelect(category?.name, true);
                                  }
                                }}
                              >
                                {!isExpanded &&
                                  Array.isArray(categoryOfferings) &&
                                  categoryOfferings.length > 3 &&
                                  "See All"}
                              </button>
                            )}
                          </div>

                          <div className="grid xl:grid-cols-3 lg:grid-cols-1 grid-cols-1 gap-4">
                            {offeringsToDisplay.length > 0 ? (
                              offeringsToDisplay.map((offering) => {
                                let isExist = false;

                                return (
                                  <Link
                                    to={`/partner/${
                                      offering?.userId?.userName ||
                                      offering?.userId?._id ||
                                      offering?.userId
                                    }/${offering?.offeringCategory?.name}`}
                                    className={`cursor-pointer  no-underline text-black`}
                                    state={offering}
                                    key={offering?._id}
                                  >
                                    <div className="bg-[#FFF] border-[1px] border-[#DBDBDB] h-full rounded-[12px] flex flex-col justify-between">
                                      <div className="py-[20px] px-[20px]">
                                        <div className="flex justify-between">
                                          <img
                                            src={
                                              typeof offering?.basicDetails?.[
                                                "Website LOGO"
                                              ]?.[0] === "string"
                                                ? `${process.env.REACT_APP_ASSET_URL}/${offering?.basicDetails?.["Website LOGO"]?.[0]}`
                                                : "/assets/images/Home-Page/marketPlace1.png"
                                            }
                                            alt=""
                                            className="w-12 h-12 rounded-full"
                                          />

                                          <h4 className="flex gap-2 font-[510] text-[13px] leading-[15.6px]">
                                            {offering?.basicDetails
                                              ?.websiteURL && (
                                              <a
                                                href={
                                                  offering?.basicDetails
                                                    ?.websiteURL
                                                }
                                                onClick={(e) =>
                                                  e.stopPropagation()
                                                }
                                                rel="noopener noreferrer"
                                                target="_blank"
                                                className="text-[#5E5E5E] !no-underline d-flex items-center gap-1"
                                              >
                                                Visit website{" "}
                                                <img
                                                  src="/assets/images/MarketPlace/arrow.svg"
                                                  alt="arrow"
                                                />
                                              </a>
                                            )}
                                          </h4>
                                        </div>
                                        <div className="flex justify-between items-center">
                                          <h1 className="font-[510] text-[16px] mt-[14px] leading-[19.2px] text-[#000000]">
                                            {offering?.basicDetails
                                              ?.websiteName ?? "No Name"}
                                          </h1>
                                        </div>
                                        <div className="flex flex-wrap gap-2 mt-[14px]">
                                          <div className="metric-info ">
                                            <FaRegEye className="text-[#7D7D7D]" />
                                            <span className="font-400 text-[12px] leading-14.32">
                                              {formatNumber(
                                                offering?.basicDetails
                                                  ?.websiteTraffic
                                              )}
                                            </span>
                                          </div>
                                          <div className="metric-info">
                                            <span className="font-400 text-[12px] leading-14.32">
                                              KPR {offering?.kprMetric}/10
                                            </span>
                                          </div>
                                          <div className="metric-info">
                                            {offering?.contentOfferings[0]
                                              ?.typeId?.name && (
                                              <span className="font-400 text-[12px] leading-14.32">
                                                {
                                                  offering?.contentOfferings[0]
                                                    ?.typeId?.name
                                                }
                                              </span>
                                            )}
                                          </div>
                                          {offering?.basicDetails
                                            ?.geoLocations && (
                                            <div className="metric-info">
                                              <TbWorld className="text-[#7D7D7D]" />
                                              <span className="font-400 text-[12px] leading-14.32">
                                                {Array.isArray(
                                                  offering?.basicDetails
                                                    ?.geoLocations
                                                ) &&
                                                  offering?.basicDetails?.geoLocations
                                                    ?.slice(0, 3)
                                                    ?.join(", ")}
                                              </span>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div>
                                        <div className="border-b w-[100%] h-[1px] bg-[#DBDBDB]"></div>

                                        <div className="flex justify-between items-center py-[10px] px-[20px]">
                                          <div>
                                            {showFilter ? (
                                              <h4 className="text-[400] text-[13px] leading-[19.5px] text-[#999999] mb-1">
                                                Price
                                              </h4>
                                            ) : (
                                              <h4 className="text-[400] text-[13px] leading-[19.5px] text-[#999999] mb-1">
                                                Starting from
                                              </h4>
                                            )}

                                            <h3 className="text-[#000000] text-[590] text-[16px] leading-[19.2px] mb-0">
                                              $
                                              {(() => {
                                                let targetId = null;

                                                const findMinWithTarget = (
                                                  offerings,
                                                  key
                                                ) => {
                                                  if (
                                                    !offerings ||
                                                    offerings.length === 0
                                                  ) {
                                                    return Infinity;
                                                  }

                                                  let minValue = Infinity;


                                                  offerings.forEach(
                                                    (offering) => {
                                                      if (
                                                        offering?.[key] <
                                                          minValue &&
                                                        (offering?.selectedPrice ===
                                                          undefined ||
                                                          offering?.selectedPrice ===
                                                            null)
                                                      ) {
                                                        minValue =
                                                          offering[key];
                                                        targetId = offering._id;
                                                      }
                                                    }
                                                  );

                                                  return minValue;
                                                };

                                                const minLivePrice =
                                                  findMinWithTarget(
                                                    offering?.contentOfferings,
                                                    "livePrice"
                                                  );


                                                let priceToFormat; // Declare a variable to hold the price

                                                if (
                                                  !minLivePrice ||
                                                  isNaN(minLivePrice) ||
                                                  minLivePrice === Infinity
                                                ) {
                                                  const minMarketKitPrice =
                                                    findMinWithTarget(
                                                      offering?.contentOfferings,
                                                      "mediaKitprice"
                                                    );
                                                  if (targetId) {
                                                    offering.targetId =
                                                      targetId;
                                                    isExist =
                                                      checkItemExistInCart(
                                                        targetId
                                                      );
                                                  }
                                                  priceToFormat =
                                                    minMarketKitPrice; // Assign the correct value
                                                } else {
                                                  if (targetId) {
                                                    offering.targetId =
                                                      targetId;
                                                    isExist =
                                                      checkItemExistInCart(
                                                        targetId
                                                      );
                                                  }
                                                  priceToFormat = minLivePrice; // Assign the correct value
                                                }

                                                // Handle cases where priceToFormat is still Infinity or undefined after all calculations
                                                if (
                                                  !priceToFormat ||
                                                  priceToFormat === Infinity ||
                                                  isNaN(priceToFormat)
                                                ) {
                                                  return "0"; // Or some other default value like "N/A"
                                                }

                                                return formateNumber(
                                                  priceToFormat
                                                ); // Correct function call here!
                                              })()}
                                            </h3>
                                          </div>
                                          <div className="flex items-center gap-2">
                                            {isAdvertiser ? (
                                              <img
                                                src="/assets/images/MarketPlace/plus.svg"
                                                alt="plus"
                                              />
                                            ) : (
                                              ""
                                            )}
                                            {isAdvertiser && (
                                              <>
                                                {!isExist ? (
                                                  <button
                                                    className="w-[20px] h-[20px] flex items-center justify-center"
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      e.stopPropagation();
                                                      if (!isAuthenticated) {
                                                        navigate("/sign-in");
                                                        return;
                                                      }
                                                      if (!loading) {
                                                        handleAddToCart(
                                                          offering?.targetId
                                                        );
                                                      }
                                                    }}
                                                  >
                                                    <IoCartOutline className="w-[24px] h-[24px]" />
                                                  </button>
                                                ) : (
                                                  <button
                                                    disabled={true}
                                                    className="font-[590] text-[13px] flex items-center gap-1"
                                                  >
                                                    <FaCheckCircle className="text-lg cursor-pointer text-green-500" />
                                                  </button>
                                                )}
                                              </>
                                            )}
                                            {/* {isAdvertiser && (
                                              <button
                                                className="w-[20px] h-[20px] flex items-center justify-center"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  e.stopPropagation();
                                                  if (!isAuthenticated) {
                                                    navigate("/sign-in");
                                                    return;
                                                  }
                                                  if (!loading) {
                                                    handleAddToCart(
                                                      offering?.targetId
                                                    );
                                                  }
                                                }}
                                              >
                                                <IoCartOutline className="w-[24px] h-[24px]" />
                                              </button>
                                            )} */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                );
                              })
                            ) : (
                              <p>No data present</p>
                            )}
                          </div>
                        </div>
                      );
                    })
                )}
              </div>
              <div className="col-lg-4 xl:!w-[30%] md:!w-[40%] !w-full">
                <MarketplaceBrand selectedCategory={selectedCategories[0]} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
