import { formateNumber } from "../Components/common/formateNumber";
import Widget from "../Components/widget/Widget";

const OrderCount = ({ totalValue, completedOrders, totalDocuments }) => {
  return (
    <div>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-6">
        <Widget
          number={totalDocuments}
          title={"Total Orders"}
          bgcolor={"bg-[#e3f5ff]"}
        />
        <Widget
          number={"$" + formateNumber(totalValue)}
          title={"Total Order Value"}
          bgcolor={"bg-[#e5ecf6]"}
        />
        <Widget
          number={completedOrders}
          title={"Completed Order"}
          bgcolor={"bg-[#e3f5ff]"}
        />
      </div>
    </div>
  );
};

export default OrderCount;
