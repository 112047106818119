import React, { useEffect, useState } from "react";
import { FaWallet } from "react-icons/fa";
import { CgWebsite } from "react-icons/cg";
import { authApi } from "../../api";
import { IoIosCloseCircleOutline } from "react-icons/io";
import toast from "react-hot-toast";
import { formateNumber } from "../common/formateNumber";

export default function Wallet() {
  const [amount, setAmount] = useState(0);
  const [open, setOpen] = useState(false);
  const [withdrawls, setWithdrawls] = useState();
  const [pendingWithdrawal, setPendingWithdrawal] = useState(0);
  const [userAmount, setUserAmount] = useState(0);
  

  useEffect(() => {
    const fetchWallet = async () => {
      try {
        const response = await authApi.get(`/withdraw/fetch`);
        console.log({ response });

        setPendingWithdrawal(
          response?.data?.data?.reduce((acc, curr) => {
            if (curr.status === "requested") {
              return acc + curr.amount;
            }
            return acc;
          }, 0)
        );
      } catch (err) {
        console.log(err);
      }
    };

    fetchWallet();
  }, []);

  useEffect(() => {
    const fetchPublishAmount = async () => {
      try {
        const response = await authApi.get(`/order/getpublishAmount?status=completed`);
        console.log("ORDERS", response?.data?.order);
    
        const withdrawalAmt = response?.data?.order?.reduce((acc, order) => acc + (order?.totalAmount || 0), 0);
    
        console.log({ withdrawalAmt });
        setWithdrawls(withdrawalAmt);
      } catch (err) {
        console.error("Error fetching publish amount:", err);
      }
    };
    
    fetchPublishAmount();
  }, []);

  const handleWithdraw = async () => {
    if (!amount) {
      toast.error("Please enter amount!!");
      return;
    }
    if (
      (
        amount >
        (withdrawls - (userAmount || 0) - (pendingWithdrawal || 0)).toFixed(2)
      )
    ) {
      toast.error("You can't withdraw more than your balance!!");
      return;
    }

    try {
      const response = await authApi.post(`/withdraw/withdraw`, { amount });
      toast.success("Withdraw request sent!!");
      setPendingWithdrawal(pendingWithdrawal + Number(amount));
      setAmount(0);
      setOpen(false);
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || "Something went wrong!!");
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await authApi.get(`/auth/fetch`);
        console.log(response.data.user?.withdrawAmount);
        
        setUserAmount(response?.data?.user?.withdrawAmount);
      } catch (err) {
        console.log(err);
      }
    };

    fetchUserData();
  }, []);
  console.log(withdrawls, userAmount, pendingWithdrawal);

  return (
    <>
      {open && (
        <div className="fixed z-50 inset-0 bg-black/[75%] flex justify-center items-center">
          <div className="relative flex flex-col bg-white rounded-lg p-8 w-80 shadow-lg">
            <button
              className="absolute top-2 right-2 text-gray-400 hover:text-gray-600 transition-transform transform hover:scale-110"
              onClick={() => {
                setAmount(0);
                setOpen(false);
              }}
            >
              <IoIosCloseCircleOutline size={28} />
            </button>
            <input
              type="number"
              placeholder="Enter Amount"
              className="w-full mb-4 p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={amount > 0 ? amount : ""}
              onChange={(e) => {
                if (open < Number(e.target.value)) {
                  toast.error(
                    "Withdrawal amount should be less than or equal to your balance."
                  );
                  return;
                }
                setAmount(e.target.value);
              }}
            />
            <button
              className="w-full bg-gray-900 text-white py-2 rounded hover:bg-gray-800 transition duration-200"
              onClick={() => {
                handleWithdraw();
              }}
            >
              Request withdraw
            </button>
          </div>
        </div>
      )}

      <div className="sticky top-[100px]">
        <div className="bg-[#18171C] w-auto p-4 mt-[40px] flex flex-col justify-center h-auto rounded-[12px]">
          <div>
            {/* <FaWallet className="w-[32px] h-[32px] text-[#fff]" /> */}
            <img src="/assets/images/Home-Page/wallet.svg" alt="wallet" />
            <h4 className="font-[300] tracking-[1px] mt-[10px] text-[16px] leading-[19.09px] mb-0 text-[#FFFFFF]">
              Total Balance
            </h4>
          </div>
          <div className="flex justify-between items-center mt-[40px]">
            <h1 className="font-[590] text-[31px] text-[#FFFFFF] mr-[20px]">
              $
              {(
                withdrawls - (userAmount || 0) - (pendingWithdrawal || 0) || 0
              ).toFixed(2)}
            </h1>
            <button
              className="font-[500] text-[14px] text-[#000] bg-[#FFFFFF] py-[5px] px-[20px] rounded-[3px]"
              onClick={() =>
                setOpen(
                  (
                    withdrawls - (userAmount || 0) - (pendingWithdrawal || 0) ||
                    0
                  ).toFixed(2)
                )
              }
            >
              Withdraw Balance
            </button>
          </div>
        </div>

        <div className="bg-[#DBDBDB] w-auto p-4 mt-[30px] flex flex-col justify-center h-auto rounded-[12px]">
          <div>
            <CgWebsite className="w-[32px] h-[32px] text-[#000000]" />
            <h4 className="font-[400] tracking-[1px] text-[#5E5E5E] mt-[10px] text-[16px] leading-[19.09px] mb-0">
              Your Website
            </h4>
          </div>
          <span className="font-[510] tracking-[0.5px] text-[13px] mt-[35px] leading-[15.6px] text-[#0A0A0A]">
            Running
          </span>
          <div className="flex justify-between items-center mt-[8px]">
            <h1 className="font-[590] text-[31px] text-[#000] mr-[20px]">
              ${ formateNumber(pendingWithdrawal)}
            </h1>
            {/* <button className="font-[500] text-[14px] text-[#fff] bg-[#0A0A0A] py-[5px] px-[20px] rounded-[3px]">
              Add Website
            </button> */}
          </div>
        </div>
      </div>
    </>
  );
}
