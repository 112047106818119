import React from 'react'
import Index from '../Components/my-cart/Index'
import { Wrapper } from '../layout'
import PackageFooter from '../Components/package/PackageFooter'

const MyCart = () => {
  return (
    <Wrapper >
      <Index />
      <PackageFooter />
    </Wrapper >
  )
}

export default MyCart
