import React, { useMemo } from 'react';
import FindNeeds from './FindNeeds';
import TrustedBy from './TrustedBy';
import Subscription from './subscription';
import Testimonial from './testimonial'
import PackageFaq from './PackageFaq';
import PackageFooter from './PackageFooter';
import { Link } from 'react-router-dom';
import { IoCartOutline } from 'react-icons/io5';
import { useSelector } from 'react-redux';

const Index = () => {

    const cartData = useSelector((state) => state.cart.cartItems);
     const countCart = useMemo(() => {
        if (!cartData) return 0;
    
        const allItems = [
          ...(cartData.adminPackages || []),
          ...(cartData.offerings || []),
          ...(cartData.addOn || []),
          ...(cartData.packages || []),
        ];
    
        return allItems.reduce((acc, item) => {
          if (item._id || item.id) {
            acc += 1;
          }
          return acc;
        }, 0);
      }, [cartData]);
      
    return (
        <>
            <div className='sticky-header'>
                <div id="main-wrapper" className="main-wrapper">
                    <FindNeeds />
                    <TrustedBy />
                    <Subscription />
                    {/* <Testimonial /> */}
                    {/* <PackageFaq/> */}
                    {/* <PackageFooter/>*/}

                    <div className='w-[50px] h-[50px] shadow flex items-center justify-center bg-white rounded-full fixed bottom-20 right-5'>
                        <Link to="/my-cart" className="relative d-lg-block d-none">
                            <IoCartOutline className="text-[28px] text-[#5E5E5E] d-lg-block d-none" />
                            <span className="bg-black absolute -top-2 -right-2 w-[20px] h-[20px] text-xs shadow rounded-full flex items-center justify-center text-white">
                            {countCart}
                            </span>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Index;