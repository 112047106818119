import React, { useEffect, useState } from 'react'

const PackageLogoGrid = () => {

    const [activeImage, setActiveImage] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveImage((prev) => !prev);
        }, 3000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div>
            <h1 class="lg:text-[50px] md:text-[40px] text-[30px] text-center mb-[30px]">Proven <span class="text-[#413ada]">Marketing Impact</span></h1>
            <div className="grid lg:grid-cols-4 grid-cols-2 gap-[10px]">
                {[1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
                    <div
                        key={item}
                        className={`bg-[#f8f8f8] py-[32px] px-[16px] rounded-lg flex flex-col items-center justify-center relative h-[7rem] overflow-hidden`}
                    >
                        <div style={{ maxHeight: "3rem", overflow: 'hidden' }}>
                            <div className={activeImage ? 'translate-y-[-54%]' : 'translate-y-0'} style={{ transitionDuration: '1s' }}>
                                {/* First Image */}
                                <img
                                    src={`/assets/images/MarketPlace/logo${item}.png`}
                                    alt="logo"
                                    className={`transition-transform max-h-[3rem] duration-100`}
                                />
                                {/* Second Image */}
                                <img
                                    src={`/assets/images/MarketPlace/logo${item}-${item}.png`}
                                    alt="logo"
                                    className={` transition-transform max-h-[3rem] duration-100`}
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default PackageLogoGrid