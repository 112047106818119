import { useEffect } from "react";

function IntercomChat() {
  useEffect(() => {
    const intercomScript = document.createElement("script");
    intercomScript.type = "text/javascript";
    intercomScript.async = true;
    intercomScript.src = `https://widget.intercom.io/widget/${process.env.REACT_APP_INTERCOM_APP_ID}`;
    document.head.appendChild(intercomScript);

    window.intercomSettings = {
      app_id: process.env.REACT_APP_INTERCOM_APP_ID,
    };

    return () => {
      const intercomScriptNode = document.querySelector(
        `script[src="https://widget.intercom.io/widget/${process.env.REACT_APP_INTERCOM_APP_ID}"]`
      );
      if (intercomScriptNode) {
        document.head.removeChild(intercomScriptNode);
      }
      if (window.Intercom) {
        window.Intercom("shutdown");
      }
    };
  }, []);

  return null;
}

export default IntercomChat;
