import React from 'react';

const FindNeeds = () => {
    return (
        <>
            <div className='container'>
                <div className='lg:my-[150px] md:my-[100px] my-[80px]'>
                    <div className=''>
                        <h1 className='font-[400] lg:text-[52px] text-[31px] lg:leading-[62.4px] leading-[37.2px] text-[#000000] md:mt-auto mt-[40px] text-center'>Skyrocket Your Growth with Precision</h1>
                        <h1 className='font-[400] lg:text-[52px] text-[31px] lg:leading-[62.4px] leading-[37.2px] text-[#000000] md:mt-aut text-center'>Web 3.0 Marketing Packages</h1>
                        <p className='font-[400] lg:text-[24px] text-[16px] lg:leading-[28.8px] leading-[24px] text-[#5E5E5E] text-center'>More Reach. More Impact. More Results.</p>
                    </div>
                    {/* <div>
                        <img src="/assets/images/Package/Video-Thubnail.png" alt="" />
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default FindNeeds;