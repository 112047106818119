import React, { useState, useEffect, useCallback } from "react";
import { FaSquarePlus } from "react-icons/fa6";
import { RiDeleteBinLine } from "react-icons/ri";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import { BASEURL } from "../../baseUrl";
import { authApi } from "../../api/index";
import { Formik } from "formik";
import * as Yup from "yup";
import { formateNumber } from "../common/formateNumber";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const CreatePackage = ({
  setIsPackageCreated,
  packageType,
  handleGetData,
  offeringList,
  fetchPackages,
  setOfferingsTypeList,
}) => {
  const [offeringCategoryLs, setOfferingCategoryLs] = useState([]);
  const [validationSchema] = useState(
    Yup.object().shape({
      name: Yup.string().required("Required"),
      // offeringCategoryId: Yup.string().required("Required"),
      discountedAmount: Yup.number().required("Required"),
      totalAmount: Yup.number().required("Required"),
      additionalDetails: Yup.string().required("Required"),
      validity: Yup.number().required("Required"),
    })
  );
  const initialFormState = {
    name: "",
    offerings: [],
    totalAmount: 0,
    discountedAmount: 0,
    additionalDetails: "",
    validity: "15",
  };

  useEffect(() => {
    const fetchOfferingCategoryLs = async () => {
      try {
        const response = authApi.get(`${BASEURL}/offering-categories`);
        setOfferingCategoryLs((await response).data.offeringCategoryList);
      } catch (error) {
        console.log(error);
      }
    };

    fetchOfferingCategoryLs();
  }, []);

  const packageName = [
    {
      _id: "",
      name: "Select Package Name",
      value: "",
    },
    {
      _id: "basic",
      name: "Basic",
      value: "basic",
    },
    {
      _id: "silver",
      name: "Silver",
      value: "silver",
    },
    {
      _id: "advance",
      name: "Advance",
      value: "advance",
    },
  ];
  const packageValidity = [
    {
      _id: "15",
      name: "15 Days",
      value: "15",
    },
    {
      _id: "30",
      name: "1 Month",
      value: "30",
    },
    {
      _id: "60",
      name: "2 Month",
      value: "60",
    },
  ];

  const [isActive, setIsActive] = useState(false);
  const [formData, setFormData] = useState(initialFormState);
  const [isActive1, setIsActive1] = useState(false); // Added isActive1 state
  const [isActive2, setIsActive2] = useState(false);

  useEffect(() => {
    fetchPackagesData();
    fetchOfferingsData();
  }, []);

  const fetchPackagesData = async () => {
    try {
      const response = await authApi.get(`/packages`, {
        params: {
          status: "created",
        },
      });

      setFormData((prev) => ({
        ...(prev || []),
        packagesData: response.data?.packageList || [],
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchOfferingsData = async () => {
    try {
      const response = await authApi.get(`/offerings/my`, {
        // params: {
        //   status: "created",
        // },
      });
      const data = response.data?.offeringList;

      const offeringData = data.flatMap((offering) =>
        offering?.contentOfferings.map((contentOffering) => ({
          typeId: contentOffering.typeId._id,
          typeName: contentOffering.typeId.name,
          offeringId: offering._id,
        }))
      );
      setOfferingsTypeList(
        data.flatMap((offering) =>
          offering?.contentOfferings.map((contentOffering) => contentOffering)
        )
      );
      setFormData((prev) => ({
        ...(prev || []),
        offeringsData: offeringData || [],
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddOffering = (val) => {
    const newOffering = {
      id: val?._id,
      name: val?.typeId?.name,
      price: val?.mediaKitprice,
      qty: 1,
    };
    setFormData((prev) => ({
      ...(prev || []),
      offerings: [...prev?.offerings, newOffering],
    }));
  };

  const handleDeleteOffering = (index) => {
    const updatedOfferings = [...formData.offerings];
    updatedOfferings.splice(index, 1);
    setFormData((prev) => ({
      ...(prev || []),
      offerings: updatedOfferings,
    }));
  };

  const incrementQuantity = (index) => {
    const updatedOfferings = [...formData.offerings];
    updatedOfferings[index].qty++;
    setFormData((prev) => ({
      ...prev,
      offerings: updatedOfferings,
    }));
  };

  const decrementQuantity = (index) => {
    const updatedOfferings = [...formData.offerings];
    if (updatedOfferings[index].qty > 1) {
      updatedOfferings[index].qty--;
      setFormData((prev) => ({
        ...prev,
        offerings: updatedOfferings,
      }));
    }
  };

  const handleCreatePackage = async (data) => {
    try {
      let response;
      data.totalAmount = calculateTotalAmount();
      if (!data?._id) {
        await authApi.post(`/packages`, {
          ...data,
          offerings: formData?.offerings,
        });
      } else {
        await authApi.patch(`/packages/${data?._id}`, {
          ...data,
          offerings: formData?.offerings,
        });
      }
      setFormData({});
      fetchPackages();
      setIsPackageCreated(true);
    } catch (error) {
      console.log(error);
    }
  };

  const getTotalAmount = (offerings = []) => {
    if (!offerings || offerings.length === 0) return 0;
    return offerings.reduce(
      (total, offering) => total + offering.price * offering.qty,
      0
    );
  };

  // Memoize the total amount calculation
  const calculateTotalAmount = useCallback(() => {
    return getTotalAmount(formData?.offerings);
  }, [formData?.offerings]);

  return (
    <Formik
      initialValues={initialFormState}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        handleCreatePackage(values);
        resetForm();
      }}
    >
      {({
        values,
        errors,
        Field,
        setValues,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <form
          onSubmit={handleSubmit}
          className="bg-white border-1 border-[#DBDBDB] rounded-lg py-3 px-4"
        >
          <h3 className="text-[18px] font-[590] mb-4">Create Package</h3>

          <div className="mb-3">
            <label className="text-[14px] mb-2">Select Package Name</label>
            <div className="dropdown rounded-lg borders cursor-pointer">
              <div
                onClick={() => setIsActive(!isActive)}
                className="dropdown-btn flex items-center justify-between p-[12px] "
              >
                <span className="md:text-sm text-[13px]">
                  {packageName?.find((i) => i._id === values?.name)?.name || ""}
                </span>
                <span
                  className={
                    isActive ? "fas fa-chevron-up" : "fas fa-chevron-down"
                  }
                />
              </div>
              <div
                className="dropdown-menu mt-2 w-full"
                style={{ display: isActive ? "block" : "none" }}
              >
                {packageName?.map((i) => (
                  <div
                    key={i._id}
                    onClick={(e) => {
                      // setFormData({});
                      // setValues({});
                      setFieldValue("name", i.value);

                      handleGetData(i.value, setValues, setFormData);
                      setIsActive(!isActive);
                    }}
                    className="dropdown-item md:text-sm text-[13px]"
                  >
                    {i.name}
                  </div>
                ))}
              </div>
            </div>
            {errors.name && (
              <span className="text-sm text-red-500">{errors.name}</span>
            )}
          </div>
          <div className="mb-3">
            <label className="text-[14px] mb-2">Choose Offering</label>
            <div className="flex items-center">
              <div className="w-full">
                <div className="dropdown rounded-lg borders cursor-pointer">
                  <div
                    onClick={() => setIsActive1(!isActive1)}
                    className="dropdown-btn flex items-center justify-between p-[10px] "
                  >
                    <span className="md:text-sm text-[13px]">
                      {
                        offeringList?.find(
                          (i) => i._id === values?.choosenOffering?._id
                        )?.typeId?.name
                      }
                    </span>
                    <span
                      className={
                        isActive1 ? "fas fa-chevron-up" : "fas fa-chevron-down"
                      }
                    />
                  </div>
                  <div
                    className="dropdown-menu mt-2 w-full"
                    style={{ display: isActive1 ? "block" : "none" }}
                  >
                    {offeringList?.map((item, index) => (
                      <div
                        key={index}
                        onClick={() => {
                          setFieldValue("choosenOffering", item);
                          setIsActive1(false);
                        }}
                        className="dropdown-item md:text-sm text-[13px]"
                      >
                        {item?.typeId?.name}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <FaSquarePlus
                onClick={() => {
                  if (!values.choosenOffering) {
                    return;
                  }
                  // if (values.choosenOffering) {
                  //   return;
                  // }
                  setFieldValue("choosenOffering", "");
                  handleAddOffering(values.choosenOffering);
                }}
                className="text-[34px] ms-2 cursor-pointer"
              />
            </div>
          </div>
          <div className="mb-5">
            <p className="font-[510]">Selected Offerings for Package</p>
            <ul>
              {Array.isArray(formData?.offerings) &&
                formData?.offerings?.map((offering, index) => (
                  <li
                    key={index}
                    className="bg-[#F3F3F3] px-[10px] mb-2 py-[5px] flex items-center justify-between border-1 border-[#DDDDDD] select-none"
                  >
                    <div className="d-flex flex-md-row flex-column flex-grow">
                      <span className="md:text-base flex-grow text-[13px] font-[590]">
                        {offering?.name ??
                          offeringList?.find(
                            (i) =>
                              i?._id?.toString() === offering?.id?.toString()
                          )?.typeId?.name}
                      </span>
                      <span className="md:text-base flex-grow text-[13px]">
                        Price: ${" "}
                        {formateNumber(offering?.price * offering?.qty)}
                      </span>
                    </div>
                    <span className="flex items-center gap-3">
                      <div className="flex items-center justify-between px-[10px] py-[5px] bg-white w-[144px]">
                        <span>{offering.qty}</span>
                        <div className="cursor-pointer">
                          <MdKeyboardArrowUp
                            onClick={() => incrementQuantity(index)}
                          />
                          <MdKeyboardArrowDown
                            onClick={() => decrementQuantity(index)}
                          />
                        </div>
                      </div>
                      <RiDeleteBinLine
                        onClick={() => handleDeleteOffering(index)}
                        className="cursor-pointer"
                      />
                    </span>
                  </li>
                ))}
            </ul>
          </div>

          <div className="row gy-3 sign-up mb-3">
            <div className="col-md-6">
              <label className="text-sm font-[510]">Total Amount</label>
              <input
                type="text"
                name="totalAmount"
                value={formateNumber(calculateTotalAmount())}
                placeholder="00"
                disabled
                onChange={handleChange}
                className="sign-input p-2"
              />

              {errors.totalAmount && (
                <span className="text-sm text-red-500">
                  {errors.totalAmount}
                </span>
              )}
            </div>
            <div className="col-md-6">
              <label className="text-sm font-[510]">Discounted Amount</label>
              <input
                type="text"
                name="discountedAmount"
                value={formateNumber(values?.discountedAmount)}
                placeholder="00"
                onChange={handleChange}
                className="sign-input p-2"
              />
              {errors.discountedAmount && (
                <span className="text-sm text-red-500">
                  {errors.discountedAmount}
                </span>
              )}
            </div>
          </div>
          {/* <div className="mb-3">
            <label className="text-sm font-[510] mb-2">Validity</label>
            <div className="dropdown rounded-lg borders cursor-pointer">
              <div
                onClick={() => setIsActive2(!isActive2)}
                className="dropdown-btn flex items-center justify-between p-[10px] "
              >
                <span className="md:text-sm text-[13px]">
                  {packageValidity?.find((i) => i._id == values?.validity)
                    ?.name || "Choose Validity"}
                </span>
                <span
                  className={
                    isActive2 ? "fas fa-chevron-up" : "fas fa-chevron-down"
                  }
                />
              </div>
              <div
                className="dropdown-menu shadows mt-2 w-full"
                style={{ display: isActive2 ? "block" : "none" }}
              >
                {packageValidity?.map((i) => (
                  <div
                    onClick={(e) => {
                      setFieldValue("validity", i.value);
                      setIsActive2(false);
                    }}
                    className="dropdown-item md:text-sm text-[13px]"
                  >
                    {i.name}
                  </div>
                ))}
              </div>
            </div>
            {errors.validity && (
              <span className="text-sm text-red-500">{errors.validity}</span>
            )}
          </div> */}

          <div>
            <label className="text-sm font-[510] mb-2">
              Add Additional Package Details
            </label>
            {/* <textarea
              placeholder="Type here..."
              name="additionalDetails"
              value={values?.additionalDetails}
              onChange={handleChange}
              className="h-[113px] w-full border-1 border-[#DBDBDB] rounded-lg p-[10px]"
            ></textarea> */}
            <div className="h-[113px] w-full ">
              <ReactQuill
                className="h-[80%]"
                theme="snow"
                value={values?.additionalDetails}
                
                onChange={(e) => 
                  setFieldValue("additionalDetails", e)}
                // onChange={handleChange}
              />
            </div>
            {errors.additionalDetails && (
              <span className="text-sm text-red-500">
                {errors.additionalDetails}
              </span>
            )}
          </div>
          <button
            className="no-underline bg-black-color text-white mt-5 px-4 py-3 inline-block rounded-md"
            type="submit"
          >
            {values?._id ? "Edit" : "Add"} Package
          </button>
        </form>
      )}
    </Formik>
  );
};

export default CreatePackage;
