import React from "react";

const PackageSkeletonLoader = () => {
  return (
    <div className="md:w-[300px] w-[230px] flex-shrink-0 animate-pulse">
      <div className="card h-100 bg-white border-1 border-[#DBDBDB] shadow-[0px_4px_28px_#0000001C] rounded-lg overflow-hidden">
        <div className="bg-[#F5F5F5] p-2 d-flex justify-between items-center">
          <div className="h-4 w-[50%] bg-gray-300 rounded"></div>
          <div className="h-4 w-[20%] bg-gray-300 rounded"></div>
        </div>
        <div className="p-2 card-body h-[300px] flex flex-col gap-3">
          <div className="w-[32px] h-[32px] bg-gray-300 rounded"></div>
          <div className="h-4 w-full bg-gray-300 rounded"></div>
          <div className="h-4 w-3/4 bg-gray-300 rounded"></div>
          <div className="h-4 w-1/2 bg-gray-300 rounded"></div>
          <ul className="flex flex-col gap-2">
            {[...Array(3)].map((_, idx) => (
              <li key={idx} className="flex items-center gap-2">
                <div className="h-3 w-3 bg-gray-300 rounded-full"></div>
                <div className="h-3 w-1/2 bg-gray-300 rounded"></div>
              </li>
            ))}
          </ul>
        </div>
        <div className="bg-[#F5F5F5] card-footer border-0 p-2 d-flex justify-end items-center">
          {/* <div className="h-4 w-[30%] bg-gray-300 rounded"></div> */}
        </div>
      </div>
    </div>
  );
};

export default PackageSkeletonLoader;
