import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import SpecificPackage from "./SpecificPackage";
import GoalOriented from "./GoalOriented";
import { authApi } from "../../api";
import useAddCart from "../../hooks/useAddCart";
import PackageFooter from "./PackageFooter";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

const Subscription = () => {
  const [selectedCategory, setSelectedCategory] = useState({
    category: "Specific",
    subcategory: "Press Release Packages",
  });
  const [isAdvertiser, setIsAdvertiser] = useState(false);
  const { addToCart, addToCartWithAddOns, loading } = useAddCart();
  const [data, setData] = useState([]);
  const [showHelpSection, setShowHelpSection] = useState(() => {
    return localStorage.getItem("value") === "true";
  });
  const storeUserData = useSelector((state) => state.authentication.user);
  const user = JSON.parse(localStorage.getItem("user")) || storeUserData;

  useEffect(() => {
    if (!user?.userType) {
      return;
    }

    if (user?.userType === "publisher") setIsAdvertiser(false);
    else setIsAdvertiser(true);
  }, [isAdvertiser, user]);

  const subCategory = {
    Specific: [
      "Press Release Packages",
      "Sponsored Article Packages",
      "⁠Organic Article Packages",
      "⁠Listicle Article Packages",
      "⁠Twitter Packages",
      "⁠YouTube Packages",
      "⁠TikTok Packages",
      "⁠Telegram Packages",
      "⁠⁠Instagram Packages",
    ],
    goalOriented: [
      "ICO Packages",
      "⁠Token Packages",
      "⁠Exchange Listing Packages",
    ],
  };

  const handleAddToCart = async (itemId, type) => {
    try {
      if (!isAdvertiser) toast.error("You need to be an advertiser to buy");
      if (type === "whole") {
        await addToCart("adminPackages", itemId);
      } else {
        await addToCartWithAddOns({
          adminPackages: data?.[type]?._id,
          addOns: data?.[type]?.addOns
            ?.filter((i) => i?.selectedAddOn)
            ?.map((i) => i?.offeringTypeId),
        });
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
    }
  };

  useEffect(() => {
    if (!selectedCategory?.category || !selectedCategory?.subcategory) {
      return;
    }
    (async function () {
      const {
        data: { packageList },
      } = await authApi.get("/admin-package", {
        params: {
          status: "active",
          category: selectedCategory.category,
          subCategory: selectedCategory.subcategory,
        },
      });
      const packages = packageList?.map((item) => {
        return {
          ...item,
          addOns: item.addOns?.map((addOn) => {
            return {
              ...addOn,
              price: 0,
              selectedAddOn: false,
            };
          }),
        };
      });
      setData(packages);
    })();
    const isSpecificCategory = selectedCategory?.category === "Specific";
    localStorage.setItem("value", isSpecificCategory);
    setShowHelpSection(isSpecificCategory);
  }, [selectedCategory]);

  return (
    <div
      className="mt-[80px] bg-[#F1F1F1] pt-[60px]"
      style={{ borderRadius: "24px 24px 0 0" }}
    >
      <div className="container Subsciption pb-[60px]">
        <h1 className="font-[590] text-start lg:text-[31px] text-[24px] Subsciption-title">
          Packages
        </h1>
        <div className="mt-[35px]">
          <ul
            className="nav nav-tabs d-inline-flex p-2 !border-[1px] !border-[#DBDBDB]"
            id="myTab"
            role="tablist"
            style={{ backgroundColor: "#FFFFFF" }}
          >
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active md:!py-[16px] md:!px-[24px] !py-[12px] !px-[16px]"
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home-tab-pane"
                type="button"
                role="tab"
                aria-controls="home-tab-pane"
                aria-selected="true"
                onClick={() => {
                  setSelectedCategory({
                    category: "Specific",
                    subcategory: "Press Release Packages",
                  });
                }}
              >
                Specific Package
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link md:!py-[16px] md:!px-[24px] !py-[12px] !px-[16px]"
                id="profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#profile-tab-pane"
                type="button"
                role="tab"
                aria-controls="profile-tab-pane"
                aria-selected="false"
                onClick={() => {
                  setSelectedCategory({
                    category: "goalOriented",
                    subcategory: "ICO Packages",
                  });
                }}
              >
                Goal Oriented
              </button>
            </li>
          </ul>
        </div>
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="home-tab-pane"
            role="tabpanel"
            aria-labelledby="home-tab"
            tabindex="0"
          >
            <Dropdown
              className="mt-[20px] mb-5 w-full"
              value={selectedCategory?.subcategory}
            >
              <Dropdown.Toggle
                className="font-[400] border-[1px] !border-[#3762CA] shadow-[0px_4px_28px_#0000001C] text-[20px] leading-[24px] !text-[#ACACAC] !bg-[#FFFFFF]"
                id="dropdown-basic"
              >
                {/* Conditionally display selected subcategory or default text */}
                {selectedCategory?.subcategory || "Select sub category"}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {subCategory.Specific.map((subcategory) => (
                  <Dropdown.Item
                    key={subcategory}
                    onClick={() =>
                      setSelectedCategory({
                        category: "Specific",
                        subcategory,
                      })
                    }
                  >
                    {subcategory}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <SpecificPackage
              data={data}
              addToCart={handleAddToCart}
              setData={setData}
            />
          </div>
          <div
            className="tab-pane fade"
            id="profile-tab-pane"
            role="tabpanel"
            aria-labelledby="profile-tab"
            tabindex="0"
          >
            {/* <Dropdown
              className="mt-[20px] mb-5 w-full"
              value={selectedCategory?.subcategory}
            >
              <Dropdown.Toggle
                className="font-[400] text-[20px] leading-[24px] !text-[#ACACAC] !bg-[#FFFFFF]"
                id="dropdown-basic"
              >
                {selectedCategory?.subcategory || "Select sub category"}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {subCategory.goalOriented.map((subcategory) => (
                  <Dropdown.Item
                    key={subcategory}
                    onClick={() =>
                      setSelectedCategory({
                        category: "goalOriented",
                        subcategory,
                      })
                    }
                  >
                    {subcategory}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown> */}
            {/* <SpecificPackage data={data}/> */}
            <GoalOriented data={data} addToCart={handleAddToCart} />
          </div>
        </div>
      </div>
      <PackageFooter showHelpSection={showHelpSection} />
    </div>
  );
};

export default Subscription;
