import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { resetPassword } from "../redux/actions/auth.action";

const ResetPassword = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState("");


  const { passwordResetSuccess, loading, error: resetError } = useSelector(
    (state) => state.authentication
  );

  useEffect(() => {
   
    if (!state?.email) {
      navigate("/sign-in");
    }
  }, [state?.email, navigate]);

  const handleChange = (element, index) => {
    if (/^[0-9]$/.test(element.value) || element.value === "") {
      const updatedOtp = [...otp];
      updatedOtp[index] = element.value;
      setOtp(updatedOtp);
      
      if (element.value !== "" && index < otp.length - 1) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      const updatedOtp = [...otp];
      if (otp[index] !== "") {
        updatedOtp[index] = "";
        setOtp(updatedOtp);
      } else if (index > 0) {        
        document.getElementById(`otp-input-${index - 1}`).focus();
      }
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text").slice(0, 6);
    if (/^\d+$/.test(pastedData)) {
      const updatedOtp = otp.map((_, i) => pastedData[i] || "");
      setOtp(updatedOtp);
      document.getElementById(`otp-input-${Math.min(pastedData.length - 1, 5)}`).focus();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const otpString = otp.join("");
    const otpNumber = Number(otpString);

    if (otpString.length !== 6 || isNaN(otpNumber)) {
      setError("Please enter a valid 6-digit OTP.");
      return;
    }

    if (!newPassword || newPassword.length < 6) {
      setError("Password must be at least 6 characters.");
      return;
    }

    setError("");
    dispatch(resetPassword(state?.email, otpNumber, newPassword));
  };

  useEffect(() => {
    if (passwordResetSuccess) {
      navigate("/sign-in"); 
    }
  }, [passwordResetSuccess, navigate]);

  

  return (
    <div className="reset-password h-screen flex items-center justify-center">
      <div className="w-full max-w-md px-4">
        <h3 className="text-[24px] text-center mb-4">Reset Password</h3>
        <p className="text-center mb-5">
          Enter the OTP sent to <strong>{state?.email || "your email"}</strong> and set a new password.
        </p>
        <form onSubmit={handleSubmit}>
          <div className="flex justify-between mb-4">
            {otp.map((digit, index) => (
              <input
                key={index}
                id={`otp-input-${index}`}
                type="text"
                value={digit}
                onChange={(e) => handleChange(e.target, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                onPaste={handlePaste}
                maxLength="1"
                className="w-[48px] h-[48px] border border-gray-300 text-center text-[20px] rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
            ))}
          </div>

          <div className="mb-4">
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="New Password"
              className="sign-input w-full p-2"
            />
          </div>
          {error && <p className="text-danger mt-2">{error}</p>}         
          <button
            type="submit"
            className="bg-black-color block text-white rounded-lg w-full h-[46px] mt-4"
          >
            Reset Password
          </button>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
