import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { ASSETBASEURL } from "../../baseUrl";
import { useCallback, useMemo } from "react";
import { formateNumber } from "../common/formateNumber";
import { useDispatch, useSelector } from "react-redux";
import { fetchCartData } from "../../redux/actions/cart.actions";

const SpecificPackage = ({ data, addToCart, setData }) => {

  const dispatch = useDispatch();
  const cartData = useSelector((state) => state.cart.cartItems);

  const handleAddToCart = async(id, type) => {
    await addToCart(id, type);
    dispatch(fetchCartData());
  };


  const calculateAddOnPrice = useCallback(
    (index) => {
      let addOnPrice = 0;
      if (data?.[index]?.addOns) {
        data[index].addOns.forEach((addOn) => {
          if (addOn.selectedAddOn) {
            addOnPrice += parseFloat(addOn.price);
          }
        });
      }
      return addOnPrice;
    },
    [data]
  );
  return (
    <>
      <div className="row buttons">
        {Array.isArray(data) &&
          data?.length > 0 &&
          data.map((item, mainIndex) => {
            const isAddedToCart = cartData?.adminPackages?.some(
              (cart) => cart.id._id === item._id
            );

            return (
              <div
                key={item?._id}
                className="col-xl-3 col-md-6 md:m-xl-auto !mt-[10px]"
              >
                <div class="h-full bg-white rounded-xl border-2 border-gray-300 flex flex-col justify-between relative overflow-hidden Add-Grediant z-10">
                  {item?.name === "Premium" && (
                    <>
                      <img
                        src="/assets/images/Package/Second-gridiant.png"
                        className="gridiant-Image"
                        alt=""
                      />
                      <img
                        src="/assets/images/Package/Grediant.png"
                        className="gridiant-Image z-10 !-right-10"
                        alt=""
                      />
                    </>
                  )}
                  <div className="p-6 z-10">
                    <h2 class="md:text-xl text-base capitalize mb-3 font-[590]">
                      {item?.displayName || item?.name}
                    </h2>
                    <h1 class="md:text-[38px] text-[30px] mb-0 font-[510]">
                      $
                      {formateNumber(
                        item?.discountedPrice + calculateAddOnPrice(mainIndex)
                      )}
                    </h1>
                    <span className="text-[#128735] font-[590] mb-3 block line-through">
                      $
                      {formateNumber(
                        item?.price + calculateAddOnPrice(mainIndex)
                      )}
                    </span>
                    <div
                      onClick={() =>
                        !isAddedToCart && handleAddToCart(item?._id, "whole")
                      }
                      className="cursor-pointer btn_hover duration-200 bg-black-color block text-white no-underline tracking-[1px] font-[590] text-center px-[20px] py-[15px] rounded-lg"
                    >
                      {isAddedToCart ? "Added to Cart" : "Add to Cart"}
                    </div>
                    <a href="https://koinpr.com/contactsales" className="!text-black text-center block py-3 font-[510] border-b underline underline-offset-4 mb-3">
                      Contact Sales
                    </a>

                    <h3 className="font-[590] md:text-[24px] text-[20px] mb-3">
                      Includes
                    </h3>
                    <div
                      className="contentWrapper list-inside"
                      dangerouslySetInnerHTML={{
                        __html: item?.packageDescription.replace(
                          /<([a-zA-Z]+)(.*?)>/g,
                          '<$1$2 style="all: revert; margin: 0; padding: 0;">'
                        ),
                      }}
                    />

                    {Array.isArray(item?.guaranteedPublisher) &&
                      item?.guaranteedPublisher?.length > 0 && (
                        <>
                          <h3 className="font-[590] md:text-[24px] text-[20px] my-3">
                            Guaranteed publishers
                          </h3>
                          <div className="row gx-2 gy-2 mb-4">
                            {item?.guaranteedPublisher?.map(
                              (publisher, index) => (
                                <div className="col-6" key={index}>
                                  <img
                                    className="w-full h-[50%] "
                                    src={ASSETBASEURL + "/" + publisher}
                                    alt="logo3"
                                  />
                                </div>
                              )
                            )}
                          </div>
                        </>
                      )}
                    {Array.isArray(item?.addOns) &&
                      item?.addOns?.length > 0 && (
                        <>
                          <h3 className="font-[590] md:text-[24px] text-[20px] mb-3">
                            Add-ons
                          </h3>

                          <div className="row">
                            <div className="col-12">
                              {item?.addOns?.map((addOn, index) => {
                                console.log({ item: addOn?.offeringType });

                                const addonData =
                                  addOn?.offeringType?.contentOfferings?.find(
                                    (i) => i?._id === addOn.offeringTypeId
                                  );
                                const price =
                                  addonData?.livePrice > 0
                                    ? addonData?.livePrice
                                    : addonData?.mediaKitprice;
                                return (
                                  <div className="border rounded-lg p-2 mb-3 d-flex items-center justify-between">
                                    <img
                                      className="w-24 h-16 object-cover"
                                      src={
                                        ASSETBASEURL +
                                        "/" +
                                        addOn?.offeringType?.basicDetails?.[
                                          "Website LOGO"
                                        ]
                                      }
                                      // src="/assets/images/Package/logo4.png"
                                      alt="logo4"
                                    />
                                    <div>
                                      <p className="font-[590] mb-1">
                                        ${price}
                                      </p>
                                      <div className="d-flex items-center gap-2">
                                        <span className="text-[10px] font-[590]">
                                          Add
                                        </span>
                                        <Form>
                                          <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            checked={!!addOn?.selectedAddOn}
                                            onChange={(e) => {
                                              setData((prev) =>
                                                prev?.map((item) => {
                                                  return {
                                                    ...item,
                                                    addOns: item.addOns?.map(
                                                      (addOnn, idx) => {
                                                        if (
                                                          addOnn._id ===
                                                          addOn._id
                                                        ) {
                                                          return {
                                                            ...addOnn,
                                                            price: e.target
                                                              .checked
                                                              ? addOnn?.price +
                                                                price
                                                              : addOnn?.price -
                                                                price,
                                                            selectedAddOn:
                                                              e.target.checked,
                                                          };
                                                        }
                                                        return addOnn;
                                                      }
                                                    ),
                                                  };
                                                })
                                              );
                                            }}
                                          />
                                        </Form>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </>
                      )}
                  </div>

                  <div className="bg-black p-3 d-flex items-center gap-3">
                    <div className="text-white">
                      <span className="text-[13px]">Final Price</span>
                      <div className="text-[24px] font-[590]">
                        $
                        {formateNumber(
                          item?.discountedPrice + calculateAddOnPrice(mainIndex)
                        )}
                      </div>
                    </div>
                    <div className=" cursor-pointer btn_hover duration-200 hover:text-white text-center w-full p-2 bg-white  rounded ">
                      <p
                        className="font-[590] mb-0"
                        onClick={() =>
                        !isAddedToCart && handleAddToCart(item?._id, "whole")
                      }
                      >
                        {isAddedToCart ? "Added to Cart" : "Add to Cart"}
                      </p>
                      <span className="text-[10px] block">
                        (
                        {calculateAddOnPrice(mainIndex) > 0
                          ? `With`
                          : `With Out`}{" "}
                        Ad-ons)
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default SpecificPackage;
