import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { register } from "../../redux/actions/auth.action";
import AccountType from "./mobile-view/AccountType";
import Started from "./mobile-view/Started";
import SignIn from "./mobile-view/SignIn";
import api from "../../api";
import Notification from "../common/toast";
let debounceTimeout;
const Index = () => {
  const [currentComponent, setCurrentComponent] = useState("AccountType");
  const [userData, setUserData] = useState({
    fullname: "",
    email: "",
    password: "",
    confirmPassword: "",
    userType: "",
    userName: "",
  });
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const { loading, error, isEmailVerified, user } = useSelector(
    (state) => state.authentication
  );
  const [isCheckingUserName, setIsCheckingUserName] = useState(false);
  const [userNameError, setUserNameError] = useState("");
  const [toastMessage, setToastMessage] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    if (user) {
      if (!isEmailVerified) {
        navigate("/Marketplace");
      } else {
        navigate("/account-verification");
      }
    }
  }, [user, isEmailVerified, navigate]);

  const handleChange = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });

    if (e.target.name === "userName") {
      handleUserNameDebounce(e.target.value);
    }
  };

  const handleUserNameDebounce = (value) => {
    if (debounceTimeout) clearTimeout(debounceTimeout);

    debounceTimeout = setTimeout(() => {
      checkUserNameAvailability(value);
    }, 500);
  };

  const checkUserNameAvailability = async (value) => {
    if (!value) {
      setUserNameError("User Name is required");
      return;
    }
    if (!/^[a-zA-Z0-9]+$/.test(value)) {
      setUserNameError("User Name must be alphanumeric");
      return;
    }
    setToastMessage({});
    setIsCheckingUserName(true);
    setUserNameError("");

    try {
      const response = await api.post(`/auth/check-userName?userName=${value}`);

      if (response?.data?.success) {
        setUserNameError("");
        setToastMessage({
          message: "Congratulations, Your Brand Username is Available",
          status: true,
        });
      } else {
        setUserNameError("User Name is already taken");
        setToastMessage({
          message: "Sorry, Username Not Available",
          status: false,
        });
      }
    } catch (err) {
      console.error(err.message);
    } finally {
      setIsCheckingUserName(false);
    }
  };

  const handleAccountTypeChange = (e) => {
    const userType = e.target.value;
    setUserData((prevUserData) => {
      const updatedData = { ...prevUserData, userType };
      if (userType === "advertiser") {
        delete updatedData.userName;
      }
      return updatedData;
    });
  };

  const validate = () => {
    let formErrors = {};

    if (!userData.fullname) formErrors.fullname = "Full name is required";
    if (!userData.email) formErrors.email = "Email is required";
    if (!userData.password) formErrors.password = "Password is required";
    if (userData.password !== userData.confirmPassword)
      formErrors.confirmPassword = "Passwords do not match";
    if (!userData.userType) formErrors.userType = "Account type is required";

    if (userData.userType === "publisher") {
      if (!userData.userName) {
        formErrors.userName = "User Name is required";
      }
    }

    return formErrors;
  };

  const handleProceed = (e) => {
    e.preventDefault();
    const formErrors = validate();
    if (Object.keys(formErrors).length === 0) {
      const filteredData = { ...userData };
      if (filteredData.userType === "advertiser") {
        delete filteredData.userName;
      }
      dispatch(register(filteredData));
    } else {
      setErrors(formErrors);
    }
  };

  const handleBack = () => {
    setCurrentComponent("AccountType");
  };

  const handleSignIn = () => {
    setCurrentComponent("SignIn");
  };

  const handleSignUp = () => {
    setCurrentComponent("Started");
  };

  return (
    <div className="sign-up">
      <div className="d-md-flex d-none h-screen">
        <div className="col-xl-5 col-4 d-lg-block d-none w-full">
          <img
            src="/assets/images/signup/sideframe.png"
            className="h-full w-full object-cover"
            alt="slide_frame"
          />
        </div>
        <div className="col-xl-7 col-lg-8 d-md-block d-none self-center">
          <div className="py-[50px] px-[130px]">
            {toastMessage?.message && (
              <Notification
                status={toastMessage?.status}
                text={toastMessage?.message}
                className="px-[130px] mx-auto w-fit mb-5"
              />
            )}
            <img
              src="/assets/images/logo.png"
              alt="logo"
              className="d-lg-none w-[158px]"
            />
            <div className="flex items-center justify-center h-full">
              <div>
                <h3 className="text-[24px] font-[590]">Let’s Get Started</h3>
                <p>
                  Already have an account?{" "}
                  <Link to="/sign-in" className="blue-color underline-offset-4">
                    Sign in
                  </Link>
                </p>
                <form onSubmit={handleProceed}>
                  <h3 className="text-[20px] mt-5 mb-3 font-[590]">
                    Choose Your Account Type
                  </h3>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-check bg-white shadows p-[14px] rounded-lg">
                        <input
                          className="form-check-input me-3 mt-0 !w-[24px] !h-[24px]"
                          type="radio"
                          name="userType"
                          value="advertiser"
                          checked={userData.userType === "advertiser"}
                          onChange={handleAccountTypeChange}
                          id="advertiser"
                        />
                        <label
                          className="form-check-label font-[500]"
                          htmlFor="advertiser"
                        >
                          I am an advertiser
                        </label>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-check bg-white shadows p-[14px] rounded-lg">
                        <input
                          className="form-check-input me-3 mt-0 !w-[24px] !h-[24px]"
                          type="radio"
                          name="userType"
                          value="publisher"
                          checked={userData.userType === "publisher"}
                          onChange={handleAccountTypeChange}
                          id="publisher"
                        />
                        <label
                          className="form-check-label font-[500]"
                          htmlFor="publisher"
                        >
                          I am a publisher
                        </label>
                      </div>
                    </div>
                  </div>
                  {errors.userType && (
                    <p className="text-danger">{errors.userType}</p>
                  )}
                  <h3 className="text-[20px] mt-4 mb-3 font-[590]">
                    Account Details
                  </h3>
                  <div className="row gy-4">
                    <div className="col-6">
                      <div>
                        <input
                          type="text"
                          name="fullname"
                          value={userData.fullname}
                          onChange={handleChange}
                          placeholder="Full name"
                          className="sign-input"
                        />
                        {errors.fullname && (
                          <p className="text-danger">{errors.fullname}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-6">
                      <div>
                        <input
                          type="email"
                          name="email"
                          value={userData.email}
                          onChange={handleChange}
                          placeholder="Email ID"
                          className="sign-input"
                        />
                        {errors.email && (
                          <p className="text-danger">{errors.email}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-6">
                      <div>
                        <input
                          type="password"
                          name="password"
                          value={userData.password}
                          onChange={handleChange}
                          placeholder="Password"
                          className="sign-input"
                        />
                        {errors.password && (
                          <p className="text-danger">{errors.password}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-6">
                      <div>
                        <input
                          type="password"
                          name="confirmPassword"
                          value={userData.confirmPassword}
                          onChange={handleChange}
                          placeholder="Confirm Password"
                          className="sign-input"
                        />
                        {errors.confirmPassword && (
                          <p className="text-danger">
                            {errors.confirmPassword}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  {userData.userType === "publisher" && (
                    <>
                      <h3 className="text-[20px] mt-4 mb-2 font-[590]">
                        Account Details
                      </h3>
                      <p className="">
                        market.koinpr.com/partner/<strong>username</strong>
                      </p>
                      <div className="row gy-4">
                        <div className="col-6">
                          <div>
                            <input
                              type="text"
                              name="userName"
                              value={userData.userName}
                              onChange={handleChange}
                              placeholder="User Name"
                              className="sign-input"
                            />
                            {(errors.userName || userNameError) && (
                              <p className="text-danger">
                                {errors.userName || userNameError}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  <p className="mt-5">
                    By proceeding you agree to Koinpr terms and conditions{" "}
                  </p>
                  <button
                    type="submit"
                    className="bg-black-color btn_hover duration-200 px-[20px] text-white rounded-lg w-[270px] h-[46px]"
                    disabled={isCheckingUserName || loading || userNameError}
                  >
                    {loading || isCheckingUserName
                      ? "Processing..."
                      : "Proceed"}
                  </button>
                  {error && <p className="text-danger">{error}</p>}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-md-none">
        {currentComponent === "AccountType" && (
          <AccountType onProceed={handleProceed} signIn={handleSignIn} />
        )}
        {currentComponent === "Started" && (
          <Started onBack={handleBack} onSignIn={handleSignIn} />
        )}
        {currentComponent === "SignIn" && <SignIn onSignUp={handleSignUp} />}
      </div>
    </div>
  );
};

export default Index;
